<script>
    import { onMount, onDestroy, createEventDispatcher, getContext } from 'svelte';
    import { tweened } from 'svelte/motion';
    import { cubicOut } from 'svelte/easing';
    import { push, link } from 'svelte-spa-router';

    import ORDERS_CONFIG from 'configs/orders';

    import shoppingCart from 'stores/shoppingCart';
    import user from 'stores/user';

    import { formatCurrency } from 'services/util';

    import Button from 'components/Button';
    import { ConfirmModal, confirmModalOptions } from 'components/ConfirmModal';

    const dispatch = createEventDispatcher();
    const { open } = getContext('simple-modal');

    let totalCost = tweened($shoppingCart.totalCost, {
        duration: 400,
        easing: cubicOut,
    });

    let totalInterestCharge = tweened($shoppingCart.loan.totalInterestCharge || 0, {
        duration: 400,
        easing: cubicOut,
    });

    let deliveryFee = tweened($shoppingCart.delivery.isIncluded ? $shoppingCart.delivery.fee : null, {
        duration: 400,
        easing: cubicOut,
    });

    let unsubscribe = shoppingCart.subscribe((store) => {
        totalCost.set(store.totalCost);
        totalInterestCharge.set(store.loan.totalInterestCharge || 0);
    });

    let isDeliverySelected = true;

    $: {
        deliveryFee = tweened($shoppingCart.delivery.isIncluded ? $shoppingCart.delivery.fee : null, {
            duration: 400,
            easing: cubicOut,
        });
    }

    onDestroy(() => {
        unsubscribe();
    });

    function handleCheckout() {
        // let deliveryFee = formatCurrency($user.country.deliveryFee, $user.country.id, $user.country.currencyCode);

        if (!isDeliverySelected) {
            open(
                ConfirmModal,
                {
                    title: `Would you like to add delivery?`,
                    message: `We noticed that you did not add delivery to your order. In light of COVID-19, we are not currently offering in-store pickups. Your delivery fee will be based on your location and calculated at checkout. Would you like to add delivery?`,
                    confirmLabel: `Let's do it!`,
                    cancelLabel: 'Review Cart',
                    onConfirm: () => {
                        shoppingCart.setDelivery(true);
                        push('/checkout');
                    },
                    onCancel: () => {
                        // push('/checkout');
                    },
                },
                confirmModalOptions,
            );
        } else {
            push('/checkout');
        }
    }

    function handleFinanceIt() {
        dispatch('checkout');
    }

    function handleDeliverySelected() {
        shoppingCart.setDelivery(isDeliverySelected);
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .root {
        overflow: hidden;
        padding: em(25) em(25) 0 em(25);
    }

    .group {
        width: 100%;
        margin-bottom: em(40);
    }

    .group-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: em(8);
        border-bottom: 2px solid $color-gray-dark;

        &__title {
            font-size: em(20);
            font-weight: $fw-bold;
            color: $color-black;
        }
    }

    .group-header-desc {
        &__checkbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row nowrap;
        }

        &__label {
            margin-left: em(8);
            font-size: em(14);
            font-weight: $fw-bold;
            color: $color-primary;
        }

        &__link {
            color: $color-primary;
            font-size: em(14);
            text-decoration: underline;
        }
    }

    .items {
        margin: em(25) 0;
        padding: 0;
        list-style: none;
    }

    .item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: em(40);

        &--compact {
            margin-bottom: em(15);
        }

        &--bottom-border {
            padding-bottom: em(10);
            border-bottom: 1px solid $color-gray-dark;
        }

        &--top-border {
            padding-top: em(10);
            border-top: 1px solid $color-gray-dark;
        }

        &--flex-end {
            justify-content: flex-end;
        }

        &--disabled {
            opacity: 0.2;
        }

        &__quantity {
            width: 10%;
        }

        &__price {
            text-align: right;

            &--fixed-width {
                width: 30%;
            }
        }

        &__desc {
            font-size: 14px;
            color: $color-gray-text;
            font-style: italic;
        }
    }

    .item-price {
        &__price {
            margin-bottom: em(4);
            font-size: em(32);
            color: $color-primary;
            font-weight: $fw-bold;
        }
        &__desc {
            font-size: em(14);
            color: $color-primary;
            line-height: 1.25;
        }
    }

    .item-summary {
        width: 60%;
        padding-right: em(25);

        &__title {
            margin-bottom: em(5);
            font-size: em(16);
        }

        &__desc {
            font-size: em(14);
            color: $color-gray-text;
        }
    }
</style>

<div class="root">
    <div class="group">
        <div class="group-header">
            <div class="group-header__title">My Cart</div>
        </div>
        <ul class="items">
            {#each $shoppingCart.products as product, i}
                <li class="item">
                    <div class="item-summary">
                        <div class="item-summary__title">{product.name}</div>
                        <div class="item-summary__desc">{product.specs}</div>
                    </div>
                    <div class="item__quantity">x{product.quantity}</div>
                    <div class="item__price item__price--fixed-width">
                        {formatCurrency((product.salePrice || product.price) * product.quantity, $user.country.id, $user.country.currencyCode)}
                    </div>
                </li>
            {/each}
        </ul>
    </div>
    <!-- <div class="group">
        <div class="group-header">
            <div class="group-header__title">Delivery</div>
            <div class="group-header-desc">
                {#if !$shoppingCart.loan.monthlyPaymentAmount}
                    <div class="group-header-desc__checkbox">
                        <input type="checkbox" bind:checked={isDeliverySelected} on:change={handleDeliverySelected} />
                        <div class="group-header-desc__label">Add Delivery</div>
                    </div>
                {:else}
                    <a class="group-header-desc__link" use:link href="/cart">Modify Delivery</a>
                {/if}
            </div>
        </div>
        <ul class="items">
            <li class="item" class:item--disabled={!$shoppingCart.delivery.isIncluded}>
                <div class="item-summary">
                    <div class="item-summary__title">Home Delivery</div>
                    <div class="item-summary__desc">Flat Fee based on your Location and calculated at Checkout</div>
                </div>
                <div class="item__quantity" />
                <div class="item__price">
                    {#if $deliveryFee}
                        {formatCurrency($deliveryFee, $user.country.id, $user.country.currencyCode)}
                    {:else}TBD{/if}
                </div>
            </li>
        </ul>
    </div> -->
    <div class="group">
        <div class="group-header">
            <div class="group-header__title">Total</div>
        </div>
        <ul class="items">
            <li class="item item--compact">
                <div class="item-summary">
                    <div class="item-summary__title">Products</div>
                    <div class="item-summary__desc" />
                </div>
                <div class="item__quantity" />
                <div class="item__price">
                    {formatCurrency($totalCost, $user.country.id, $user.country.currencyCode)}
                </div>
            </li>
            {#if $shoppingCart.delivery.isIncluded && $shoppingCart.delivery.fee > 0}
                <li class="item item--compact">
                    <div class="item-summary">
                        <div class="item-summary__title">Home Delivery</div>
                    </div>
                    <div class="item__quantity" />
                    <div class="item__price">
                        {#if $deliveryFee}
                            {formatCurrency($deliveryFee, $user.country.id, $user.country.currencyCode)}
                        {:else}TBD{/if}
                    </div>
                </li>
            {/if}
            {#if $shoppingCart.loan.totalInterestCharge}
                <li class="item item--compact">
                    <div class="item-summary">
                        <div class="item-summary__title">

                            {#if $shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.finance}
                                <span>Interest & Fees</span>
                            {:else}
                                <span>Administration Fees</span>
                            {/if}
                        </div>
                        <div class="item-summary__desc" />
                    </div>
                    <div class="item__quantity" />
                    <div class="item__price">
                        {formatCurrency($totalInterestCharge, $user.country.id, $user.country.currencyCode)}
                    </div>
                </li>
            {/if}
            <li class="item item--compact item--top-border">
                <div class="item-summary">
                    <div class="item-summary__title">
                        {#if $shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.finance}
                            <span>Financed Amount</span>
                        {:else}
                            <span>Grand Total</span>
                        {/if}
                    </div>
                </div>
                <div class="item__quantity" />
                <div class="item__price">
                    <div class="item-price">
                        <div class="item-price__price">
                            {#if $shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.creditCard}
                                {formatCurrency($totalCost + $totalInterestCharge + $deliveryFee, $user.country.id, $user.country.currencyCode)}
                            {:else}
                                {formatCurrency($totalCost + $deliveryFee, $user.country.id, $user.country.currencyCode)}
                            {/if}
                        </div>
                        {#if $shoppingCart.loan.monthlyPaymentAmount}
                            {#if $shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.creditCard}
                                <div class="item-price__desc">
                                    A representative will be in touch to process your payment.
                                </div>
                            {:else}
                                <div class="item-price__desc">
                                    Payable in monthly installments of
                                    <strong>
                                        {formatCurrency($shoppingCart.loan.monthlyPaymentAmount, $user.country.id, $user.country.currencyCode)}
                                    </strong>
                                    over {$shoppingCart.loan.numPeriods} month(s).
                                </div>
                            {/if}
                        {/if}
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="group">
        {#if $shoppingCart.loan.monthlyPaymentAmount}
            <Button primary fullWidth onClick={handleFinanceIt}>
                {#if $shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.finance}
                    <span>Finance it</span>
                {:else}
                    <span>Purchase it</span>
                {/if}
            </Button>
        {:else}
            <Button primary fullWidth onClick={handleCheckout}>Proceed to Checkout</Button>
        {/if}

    </div>
</div>
