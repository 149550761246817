export default {
    products: {
        pagination: {
            limit: 25,
            min: 8,
            type: {
                default: 1000,
                filter: 2000,
                search: 3000,
            },
        },
    },
    filters: {
        keys: {
            retailer: 'retailer',
            category: 'category',
            sort: 'sort',
        },
        options: {
            sort: [
                {
                    label: 'Date Added (Newest to Oldest)',
                    value: {
                        property: 'dateCreated',
                        order: 'desc',
                    },
                },
                {
                    label: 'Date Added (Oldest to Newest)',
                    value: {
                        property: 'dateCreated',
                        order: 'asc',
                    },
                },
                {
                    label: 'Price (Highest to Lowest)',
                    value: {
                        property: 'price',
                        order: 'desc',
                    },
                },
                {
                    label: 'Price (Lowest to Highest)',
                    value: {
                        property: 'price',
                        order: 'asc',
                    },
                },
            ],
            categories: [
                {
                    label: 'Laptops/Tablets',
                    value: 'laptops-tablets',
                },
                {
                    label: 'Mobile Phones',
                    value: 'mobile-phones',
                },
                {
                    label: 'Healthcare & Personal Beauty',
                    value: 'healthcare',
                },
                {
                    label: 'Power Tools',
                    value: 'power-tools',
                },
                {
                    label: 'Furniture',
                    value: 'furniture',
                },
                {
                    label: 'Kitchen Appliances',
                    value: 'kitchen-appliances',
                },
                {
                    label: 'Kitchen Dining',
                    value: 'kitchen-dining',
                },
                {
                    label: 'Laundry Appliances',
                    value: 'laundry-appliances',
                },
                {
                    label: 'Smart Accessories',
                    value: 'smart-accessories',
                },
                {
                    label: 'Televisions',
                    value: 'televisions',
                },
                {
                    label: 'Motor Vehicles',
                    value: 'motor-vehicle',
                },
                {
                    label: 'Toys & Games',
                    value: 'child-care-and-toys',
                },
                {
                    label: 'Childcare',
                    value: 'childcare',
                },
                {
                    label: 'Books and Education',
                    value: 'books-education',
                },
                {
                    label: 'Back to School',
                    value: 'back-to-school',
                },
                {
                    label: 'Videos Games and Consoles',
                    value: 'video-games-consoles',
                },
                {
                    label: 'Home Decor and Fittings',
                    value: 'home-decor-fittings',
                },
            ],
        },
    },
};
