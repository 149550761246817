<script>
    import ProductGridItem from './ProductGridItem';

    export let products = [];
</script>

<style lang="scss">
    @import 'sass/base';

    .grid {
        overflow: hidden;
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
        gap: em(5);

        @include for-tablet-portrait-up {
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: em(20);
        }

        @include for-tablet-landscape-up {
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: em(20);
        }
    }
</style>

<div class="grid">
    {#each products as product, i}
        <ProductGridItem {product} />
    {/each}
</div>
