<script>
    export let iconUrl = null;
    export let primary = true;
    export let primaryInverse = false;
    export let secondary = false;
    export let secondaryInverse = false;
    export let transparent = false;
    export let cancel = false;
    export let fullWidth = false;
    export let disabled = false;
    export let isLoading = false;
    export let onClick = () => {};
</script>

<style lang="scss">
    @import 'sass/base';

    .primary {
        @include btn-primary;
    }

    .primaryInverse {
        @include btn-primary-inverse;
    }

    .secondary {
        @include btn-secondary;
    }

    .secondaryInverse {
        @include btn-secondary-inverse;
    }

    .transparent {
        @include btn-primary;
        background: transparent;
        color: $color-white;

        &:hover {
            animation: pulse;
            animation-duration: 0.25s;
            background: transparent;
            box-shadow: none;
        }
    }

    .cancel {
        @extend .transparent;
        color: $color-gray-text;
    }

    .fullWidth {
        width: 100%;
    }

    img {
        @include btn-icon;
    }
</style>

<button
    class:primary
    class:primaryInverse
    class:secondary
    class:secondaryInverse
    class:transparent
    class:cancel
    class:fullWidth
    on:click={onClick}
    {disabled}>
    {#if iconUrl && !isLoading}
        <img alt="icon" src={iconUrl} />
    {/if}
    {#if isLoading}
        <img alt="icon" src="images/btn_loading_icon.svg" height="25" />
    {/if}
    <slot />
</button>
