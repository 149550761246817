<script>
    import { createEventDispatcher } from 'svelte';

    import Lottie from 'components/Lottie';

    const dispatch = createEventDispatcher();

    let searchQuery;

    function handleSubmit(e) {
        e.preventDefault();
        dispatch('search', {
            searchQuery,
        });
    }

    function handleClear() {
        searchQuery = null;
        dispatch('search', {
            searchQuery,
        });
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .search-bar {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__input {
            position: relative;
            width: 175px;
            padding: 10px 15px;
            background: $color-gray-light;
            border: none;
            border-radius: 15px;
            outline: 0;
            font-size: em(14);
            text-align: left;

            @include for-tablet-portrait-up {
                width: 340px;
            }
        }

        &__btn {
            cursor: pointer;
            position: absolute;
            right: -5px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            background: $color-primary;
            border: none;
            border-radius: 50%;
            outline: 0;
            transition: 0.25s all ease-in;

            &:hover {
                background: darken($color-primary, 10);
            }

            &--disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        &__clear {
            cursor: pointer;
            position: absolute;
            right: 40px;
            width: 25px;
            height: 25px;
            font-size: em(12);
            font-weight: $fw-bold;
            color: $color-gray-text;
            letter-spacing: em(0.5);
            text-transform: uppercase;
        }
    }
</style>

<form on:submit={handleSubmit} class="search-bar">
    <input type="text" bind:value={searchQuery} class="search-bar__input" placeholder="Search by Product Name" />
    {#if searchQuery}
        <div class="search-bar__clear" on:click={handleClear}>
            <Lottie jsonPath="lottie/search-clear.json" loop={false} />
        </div>
    {/if}
    <button
        class="search-bar__btn"
        class:search-bar__btn--disabled={!searchQuery || searchQuery.length === 0}
        type="submit">
        <img src="images/search_icon@2x.svg" height="20" alt="search" />
    </button>
</form>
