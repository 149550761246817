<script>
    import { onDestroy } from 'svelte';
    import { fly } from 'svelte/transition';
    import { tweened } from 'svelte/motion';
    import { cubicOut } from 'svelte/easing';

    import { push } from 'svelte-spa-router';

    import shoppingCart from 'stores/shoppingCart';
    import user from 'stores/user';
    import { formatCurrency } from 'services/util';

    import Button from 'components/Button';
    import Link from 'components/Link';
    import Lottie from 'components/Lottie';

    const totalCost = tweened($shoppingCart.totalCost, {
        duration: 400,
        easing: cubicOut,
    });

    let numProducts = $shoppingCart.products.length;

    let unsubscribe = shoppingCart.subscribe((store) => {
        totalCost.set(store.totalCost);
        numProducts = store.numProducts;
    });

    function handleFinanceItClick() {
        push('/cart');
    }

    onDestroy(() => {
        unsubscribe();
    });
</script>

<style lang="scss">
    @import 'sass/base';

    .calculator {
        display: flex;
        position: relative;
        z-index: 200;
        overflow: hidden;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
        margin-top: 50px;
        padding: em(15);
        background: rgba($color-gray-light, 1);
        transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);

        @include for-tablet-portrait-up {
            justify-content: flex-start;
            padding: em(25);
        }
    }

    .calculator-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: em(15);
        font-size: em(16);
        font-weight: $fw-black;
        color: $color-black;

        @include for-tablet-portrait-up {
            width: 25%;
            font-size: em(18);
        }

        &__icon {
            display: none;
            width: 40px;
            height: 40px;
            margin-right: em(5);

            @include for-tablet-portrait-up {
                display: block;
            }
        }
    }

    .group {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-evenly;
        align-items: flex-start;
        padding-right: em(15);

        @include for-tablet-portrait-up {
            width: 25%;
            padding: 0;
        }

        &__title {
            margin-bottom: em(5);
            font-weight: $fw-light;
            font-size: em(14);

            @include for-tablet-portrait-up {
            }
        }

        &__value {
            font-weight: $fw-bold;
            font-size: em(14);
            color: $color-primary;

            @include for-tablet-portrait-up {
                font-size: em(16);
            }
        }
    }

    .actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        @include for-tablet-portrait-up {
            width: 25%;
        }

        &__btn {
            @include btn-primary;
            display: inline-block;
            align-self: flex-end;
            font-size: em(12);

            &--cancel {
                display: none;

                @include for-tablet-portrait-up {
                    display: inline-block;
                    background: transparent;
                    color: $color-gray-text;

                    &:hover {
                        background: rgba($color-black, 0.05);
                        box-shadow: none;
                    }
                }
            }
        }
    }
</style>

{#if numProducts > 0}
    <div class="calculator" in:fly={{ y: 200, duration: 500 }}>
        <div class="calculator-title">
            <div class="calculator-title__icon animate__animated animate__slideInLeft">
                <Lottie jsonPath="lottie/shopping-cart.json" loop={false} />
            </div>
            My Cart
        </div>
        <div class="group">
            <div class="group__title">Products</div>
            <div class="group__value">{numProducts} {numProducts == 1 ? 'Product' : 'Products'}</div>
        </div>
        <div class="group">
            <div class="group__title">Total Cost</div>
            <div class="group__value">
                {#if $user.country}{formatCurrency($totalCost, $user.country.id, $user.country.currencyCode)}{/if}
            </div>
        </div>
        <div class="actions">
            <button class="actions__btn" on:click={handleFinanceItClick}>Checkout</button>
            <button
                class="actions__btn actions__btn--cancel"
                on:click={() => {
                    shoppingCart.clear();
                }}>
                Clear Cart
            </button>
        </div>
    </div>
{/if}
