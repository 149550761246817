import ConfirmModal from 'ConfirmModal.svelte';

const confirmModalOptions = {
    styleWindow: {
        borderRadius: '0',
    },
    styleContent: {
        padding: 0,
    },
    closeButton: false,
    closeOnEsc: false,
    closeOnOuterClick: false,
};

export { confirmModalOptions, ConfirmModal };
