<script>

</script>

<style lang="scss">
    @import 'sass/base';

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: em(20);
        background: darken($color-white, 3%);

        &__logo {
            opacity: 0.5;
            margin-bottom: em(10);
        }

        &__legal {
            width: 100%;
            font-size: em(12);
            text-transform: uppercase;
            text-align: center;
            color: $color-gray-text;
        }
    }
</style>

<footer class="footer">
    <img src="images/footer_logo@2x.png" height="40" alt="logo" class="footer__logo" />
    <div class="footer__legal">Copyright© {new Date().getYear() + 1900} Term Finance Ltd.</div>
</footer>
