<script>
    import { getContext, onMount } from 'svelte';
    import { CountrySelectorModal, countrySelectorModalOptions } from './CountrySelectorModal';

    import user from 'stores/user';
    import { getCountryById } from 'services/user';
    import { getCountryIdByLocale } from 'services/util';

    const { open } = getContext('simple-modal');

    onMount(() => {
        if (!$user.country) {
            // Try setting Country based on user's browser locale
            // TODO: This is ugly. Refactor.
            if (navigator && navigator.languages && navigator.languages.length) {
                let locale = navigator.languages[0];
                let countryId = getCountryIdByLocale(navigator.languages[0]);
                if (countryId) {
                    getCountryById(countryId).then(
                        (country) => {
                            if (country) {
                                user.setCountry(country);
                            } else {
                                handleClick();
                            }
                        },
                        () => {
                            handleClick();
                        },
                    );
                } else {
                    handleClick();
                }
            } else {
                handleClick();
            }
        }
    });

    function handleClick() {
        open(CountrySelectorModal, {}, countrySelectorModalOptions);
    }
</script>

<style lang="scss">
    .btn {
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }
</style>

<button class="btn" on:click={handleClick}>
    {#if $user.country}
        <img src={`/images/country_${$user.country.id}_icon@2x.svg`} height="35" alt="country" />
    {:else}
        <img src="images/country_default_icon@2x.svg" height="35" alt="country" />
    {/if}
</button>
