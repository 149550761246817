export function formatCurrency(amount, locale, currency = 'USD') {
    return new Intl.NumberFormat(`en`, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
    }).format(amount);
}

export function convertDocsToArray(docs) {
    let arr = [];
    docs.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
    });

    return arr;
}

export function getCountryIdByLocale(locale) {
    let split = locale.split('-');
    if (split && split.length && split[1]) {
        return split[1].toLowerCase();
    } else {
        return null;
    }
}

export function sanitizeObject(obj) {
    return JSON.parse(JSON.stringify(obj, (k, v) => (v === undefined ? null : v)));
}

export function getDateFromTimestamp(timestamp) {
    if (!timestamp) {
        return 'N/A';
    }

    let date = new Date(timestamp.toDate());
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
}

export function generateRetailerId(retailerDisplayName, countryId) {
    return `${retailerDisplayName.toLowerCase().replaceAll("'", '').replaceAll(' ', '-')}-${countryId}`;
}

export default {
    formatCurrency,
    convertDocsToArray,
    getCountryIdByLocale,
    getDateFromTimestamp,
    generateRetailerId,
    sanitizeObject,
};
