<script>
    import { getContext } from 'svelte';
    import { push } from 'svelte-spa-router';

    import user from 'stores/user';
    import shoppingCart from 'stores/shoppingCart';
    import { formatCurrency } from 'services/util';
    import { getRetailerDisplayName } from 'services/shop';

    import { ConfirmModal, confirmModalOptions } from 'components/ConfirmModal';
    import Counter from 'components/Counter';

    const { open } = getContext('simple-modal');

    export let product;

    function add(product) {
        shoppingCart.add(product);
    }

    function remove(product, removeAll = false) {
        if ($shoppingCart.numProducts == 1 || (removeAll && $shoppingCart.numProducts === product.quantity)) {
            open(
                ConfirmModal,
                {
                    title: 'Empty your cart?',
                    message: 'Removing this product will empty your cart and redirect you to the Products page.',
                    onConfirm: () => {
                        shoppingCart.remove(product, removeAll);
                        push('/');
                    },
                },
                confirmModalOptions,
            );
        } else {
            shoppingCart.remove(product, removeAll);
        }
    }

    function handleRemoveAll(product) {
        open(
            ConfirmModal,
            {
                title: 'Remove products from your cart?',
                message: `Are you sure you want to remove ${product.quantity} ${product.name} from your cart?`,
                onConfirm: () => {
                    setTimeout(() => {
                        remove(product, true);
                    }, 100);
                },
            },
            confirmModalOptions,
        );
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .product {
        overflow: hidden;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        padding: em(20) em(5);
        border-bottom: 1px solid $color-gray-light;

        @include for-tablet-portrait-up {
            flex-flow: row nowrap;
            padding: em(20) em(25);
        }

        &__thumbnail {
            width: 50%;
            height: auto;

            @include for-tablet-portrait-up {
                width: 25%;
                min-width: 125px;
            }
        }

        &__price {
            width: 50%;
            font-size: em(24);
            font-weight: $fw-regular;

            &--sale {
                color: $color-green;
            }

            @include for-tablet-portrait-up {
                margin: 0 em(5);
            }
        }

        &__quantity {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;

            @include for-tablet-portrait-up {
                margin: 0 em(5);
            }
        }
    }

    .product-summary {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: flex-start;
        width: 50%;

        @include for-tablet-portrait-up {
            margin: 0 em(5) 0 em(25);
        }

        &__title {
            margin-bottom: em(5);
            font-size: em(18);
            font-weight: $fw-bold;
        }

        &__retailer {
            margin-bottom: em(15);
            font-size: em(14);
        }

        &__desc {
            color: $color-gray-text;
            font-size: em(14);
        }
    }

    .product-actions {
        display: none;

        @include for-tablet-portrait-up {
            width: 20%;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            border-left: 1px solid $color-gray-dark;
        }

        &__info,
        &__remove {
            cursor: pointer;
            margin: 0 em(5);
        }
    }
</style>

<div class="product">
    <div class="product__thumbnail">
        <img src={product.imageUrl ? product.imageUrl : '/images/default_product@2x.jpg'} alt="thumbnail" />
    </div>
    <div class="product-summary">
        <div class="product-summary__title">{product.name}</div>
        <div class="product-summary__retailer">{getRetailerDisplayName(product.retailerId)}</div>
        <div class="product-summary__desc">{product.specs}</div>
    </div>
    <div class="product__quantity">
        <Counter
            value={product.quantity}
            onDecrement={() => {
                remove(product);
            }}
            onIncrement={() => {
                add(product);
            }} />
    </div>
    <div class="product__price" class:product__price--sale={product.salePrice}>
        {formatCurrency(product.salePrice || product.price, $user.country.id, $user.country.currencyCode)}
    </div>
    <div class="product-actions">
        <div class="product-actions__remove" on:click={() => handleRemoveAll(product)}>
            <img src="/images/remove_icon@2x.svg" height="30" alt="remove" />
        </div>
    </div>
</div>
