<script>
    import shoppingCart from 'stores/shoppingCart';
    import Lottie from 'components/Lottie';
</script>

<style lang="scss">
    @import 'sass/base';

    .shopping-cart {
        position: relative;
        cursor: pointer;

        &__badge {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -5px;
            right: -5px;
            width: 22px;
            height: 22px;
            background: $color-secondary;
            border-radius: 50%;
            border: 2px solid $color-primary;
            font-size: 12px;
            font-weight: $fw-bold;
            color: $color-white;
        }

        &__icon {
            width: 40px;
        }
    }
</style>

<div class="shopping-cart">
    {#if $shoppingCart.products.length}
        <div class="shopping-cart__badge animate__animated animate__zoomIn">{$shoppingCart.numProducts}</div>
    {/if}
    <div class="shopping-cart__icon">
        <Lottie jsonPath="/lottie/cart-icon.json" loop={false} />
    </div>
    <!-- <img src="/images/cart_icon@2x.svg" height="35" alt="cart" /> -->
</div>
