<script>
    import { onMount, getContext } from 'svelte';
    import { link, push } from 'svelte-spa-router';
    import { values, findWhere, contains, reduce } from 'underscore';
    import Select from 'svelte-select';
    import Datepicker from 'svelte-calendar';
    import toastr from 'toastr';

    import user from 'stores/user';

    import adminService from 'services/admin';
    import utilService from 'services/util';

    import ORDERS_CONFIG from 'configs/orders';

    import Page from 'components/Page';
    import Button from 'components/Button';
    import Header from 'components/Header';
    import Nav from 'components/Nav';
    import FullLayout from 'components/FullLayout';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';
    import Lottie from 'components/Lottie';

    import { OrderActivityModal, orderActivityModalOptions } from 'components/OrderActivityModal';

    export let params = {};

    const { open } = getContext('simple-modal');

    const orderStateOptions = values(ORDERS_CONFIG.states).map((order) => {
        return { label: order.displayName, value: order.id };
    });

    const internalStateOptions = values(ORDERS_CONFIG.internalStates).map((state) => {
        return { label: state.displayName, value: state.id };
    });

    const today = new Date();
    let countries = [];
    let selectedCountry = null;
    let state = ORDERS_CONFIG.states[params.state];
    let subtitle = state['displayName'];

    let totalOrdersCount = 0;
    let totalCostForState = 0;

    let allOrders = [];
    let lastLoadedDoc = null;
    let hasMoreOrders = true;
    let isLoading = false;

    $: {
        if (params.state != state.key) {
            state = ORDERS_CONFIG.states[params.state];
            subtitle = state['displayName'];
            if (selectedCountry) {
                loadOrders(selectedCountry.value);
            }
        }
    }

    onMount(() => {
        loadCountries();

        toastr.options = {
            progressBar: true,
            positionClass: 'toast-bottom-full-width',
        };
    });

    async function loadOrders(countryId, lastDoc = null) {
        isLoading = true;

        try {
            const { orders, lastDoc: newLastDoc, hasMore } = await adminService.getOrdersByState(state.id, countryId, {
                pageSize: 50,
                lastDocSnap: lastDoc,
            });

            const formattedOrders = formatOrders(orders);

            // First load or filter change
            if (!lastDoc) {
                let aggregation = await adminService.getOrdersAggregation(state.id, countryId);
                totalCostForState = aggregation.totalAmount;
                totalOrdersCount = aggregation.count;

                allOrders = formattedOrders;
            }

            // Pagination - append to existing orders
            else {
                allOrders = [...allOrders, ...formattedOrders];
            }

            // Save the last doc for next pagination call
            lastLoadedDoc = newLastDoc;
            hasMoreOrders = hasMore;
        } catch (err) {
            console.error(err);
        } finally {
            isLoading = false;
        }
    }

    // Load next page
    function loadMoreOrders() {
        if (hasMoreOrders && !isLoading) {
            loadOrders(selectedCountry.value, lastLoadedDoc);
        }
    }

    function loadCountries() {
        return adminService.getCountries($user.profile.countries).then((data) => {
            countries = data.map((country) => {
                return {
                    label: country.displayName,
                    value: [country.id],
                    currencyCode: country.currencyCode,
                };
            });

            // Add option for all Countries
            countries.unshift({
                label: 'All Countries',
                value: data.map((country) => country.id),
            });

            selectedCountry = countries[0];
        });
    }

    function handleClickFilter(route) {
        push(route);
    }

    function canUpdateState(newStateId, order) {
        let canUpdateState = true;

        if (
            contains(
                [ORDERS_CONFIG.states.approved.id, ORDERS_CONFIG.states.transit.id, ORDERS_CONFIG.states.delivered.id],
                newStateId,
            ) &&
            (order.loan.id == null || order.loan.id.length == 0) &&
            order.paymentType == ORDERS_CONFIG.paymentTypes.finance
        ) {
            canUpdateState = false;
        }

        if (
            newStateId == ORDERS_CONFIG.states.scheduled.id &&
            (!order.dateScheduled || order.dateScheduledConfirmed != true)
        ) {
            canUpdateState = false;
        }

        return canUpdateState;
    }

    async function handleUpdateState(event, order) {
        if (!canUpdateState(event.detail.value, order)) {
            open(
                AlertModal,
                {
                    title: `Uh oh! Unable to move this order to ${event.detail.label}.`,
                    message: 'Please enter a valid Loan ID or Scheduled Delivery Date before updating the state.',
                },
                alertModalOptions,
            );

            throw 'Invalid';
        }

        return adminService.updateOrderState(order, event.detail.value);
    }

    async function handleUpdateInternalState(event, order) {
        if (event.detail.value != order.internalState) {
            await adminService.updateOrderInternalState(order, event.detail.value);
            toastr.success(`Order #${order.confirmationId} internal state updated`, 'Changes Saved');
        }
    }

    function sanitizeCurrency(value) {
        let sanitizedCurrency = parseInt(
            value.replace('$', '').replace(selectedCountry.currencyCode, '').replace(',', '').replace('.00', ''),
            10,
        );

        return Number.isInteger(sanitizedCurrency) ? sanitizedCurrency : null;
    }

    function handleFocusCreditCapacity(value, order) {
        let rawCreditCapacity = sanitizeCurrency(value);
        order.formatted.creditCapacity = rawCreditCapacity;
        allOrders = allOrders;
    }

    async function handleUpdateCreditCapacity(value, order) {
        if (value == order.loan.creditCapacity) {
            order.formatted.creditCapacity = utilService.formatCurrency(
                order.loan.creditCapacity,
                selectedCountry.value,
                selectedCountry.currencyCode,
            );

            allOrders = allOrders;

            return;
        }

        let rawCreditCapacity = sanitizeCurrency(value);

        order.loan.creditCapacity = rawCreditCapacity;
        order.formatted.creditCapacity =
            order.loan.creditCapacity != null
                ? utilService.formatCurrency(
                      order.loan.creditCapacity,
                      selectedCountry.value,
                      selectedCountry.currencyCode,
                  )
                : null;

        await handleUpdateOrder(order);

        allOrders = allOrders;
    }

    async function handleUpdateOrder(order) {
        if (order.notes && !order.notes.length) {
            order.notes = null;
        }

        await adminService.updateOrder(order);
        toastr.success(`Order #${order.confirmationId} updated!`, 'Changes Saved');
    }

    function formatOrders(data) {
        return data.map((order) => {
            order = Object.assign({}, order);

            if (order.dateScheduled != null) {
                order.dateScheduled = order.dateScheduled.toDate();
                order.dateScheduledConfirmed = true;
            } else {
                // TODO: This is hacky. Figure out why we can't removed undefined fields.
                order.dateScheduled = new Date();
                order.dateScheduledConfirmed = false;
            }

            return order;
        });
    }

    function displayActivity(order) {
        open(
            OrderActivityModal,
            {
                order,
            },
            orderActivityModalOptions,
        );
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .content {
        padding: em(25);
    }

    .filters {
        overflow: hidden;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
        // gap: em(25);
        background: $color-gray-light;

        &__filter {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            height: 50px;
            padding: em(50);
            color: $color-primary;
            font-size: em(12);
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.75px;
            border-bottom: 1px solid $color-gray-dark;
            border-right: 1px solid transparent;
            border-left: 1px solid transparent;
            transition: all 0.05s ease-in;

            a {
                color: $color-black;
            }

            &:hover {
                background: $color-white;
                border-bottom: 1px solid transparent;

                a {
                    color: $color-primary;
                    font-weight: $fw-bold;
                }
            }

            &--active {
                background: $color-white;
                border-bottom: 1px solid transparent;
                border-right: 1px solid $color-gray-dark;
                border-left: 1px solid $color-gray-dark;

                a {
                    color: $color-primary;
                    font-weight: $fw-bold;
                }
            }
        }
    }

    .secondary-filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: em(25);
        border-bottom: 1px solid $color-gray-dark;

        &__left {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__right {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .secondary-filter {
        width: 50%;
        margin: 0 em(20);

        &--actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &__label {
            margin-bottom: em(10);
            display: block;
            font-size: em(16);
            font-weight: $fw-bold;
        }

        &__select {
            width: 100%;
            font-size: em(14);
            --borderRadius: 10px;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #822c7d;
            --multiItemActiveBG: #166f86;
            --multiClearBG: #999999;
        }
    }

    .orders {
        margin-top: em(25);
    }

    .orders-table {
        &__hidden {
            display: none;
        }

        td,
        th {
            position: relative;
            height: 100%;
        }

        ::placeholder {
            font-weight: $fw-regular;
        }
    }

    .products {
        &__list {
            max-width: 175px;
            margin-left: em(25);
            list-style-type: circle;
        }
    }

    .product {
        margin-bottom: em(20);

        div {
            margin-bottom: em(5);
        }

        strong {
            color: $color-primary;
        }
    }

    .customer {
        &__headline {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: start;
            gap: 0 2px;
            margin-bottom: em(15);
        }

        &__id {
            font-size: em(18);
            letter-spacing: 1px;
            color: $color-primary;
        }

        &__name {
            margin-bottom: em(5);
            font-weight: $fw-bold;
            text-transform: capitalize;
        }

        &__employer {
            margin-bottom: em(20);
            font-style: italic;
            color: $color-gray-text;
            text-transform: capitalize;
        }

        &__contact {
            margin-bottom: em(20);
            div {
                margin-bottom: em(5);
            }
        }

        &__address {
            margin-bottom: em(10) !important;
        }
    }

    .loan {
        &__term {
            margin-bottom: em(20);

            div {
                margin-bottom: em(5);
            }
        }

        &__price {
            margin-bottom: em(20);

            div {
                margin-bottom: em(5);
            }
        }

        &__group {
            margin-bottom: em(15);
        }

        &__input {
            outline: 0;
            padding: em(7) 0 em(5) 0;
            font-weight: $fw-bold;
            color: $color-primary;
            border: none;
            border-bottom: 1px solid $color-gray-text;
        }
    }

    .delivery {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dates {
        &__group {
            margin-bottom: em(20);
        }

        &__picker {
            cursor: pointer;
            color: $color-primary;
            font-weight: $fw-bold;
        }
    }

    .cc-bg {
        padding: em(25) auto;
    }

    .cc {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: em(20);
        }

        &__desc {
            width: 175px;
            margin: 0 auto;
            text-align: center;
            line-height: em(20);
            color: $color-gray-text;
        }
    }

    .state {
        min-width: 125px;
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;

        &__btn {
            @include btn-primary;
            font-size: em(12);
        }
    }

    .notes {
        min-width: 150px;

        &__textarea {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 150px;
            padding: em(25) em(20);
            resize: none;
            border: none;
            background: $color-gray-light;
            outline: none;
            color: darken($color-gray-text, 50%);
        }

        &__actions {
            background: #fff;
        }
    }

    .message {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        padding: em(25);
        font-size: em(24);
        color: $color-gray-text;

        &__loader {
            width: 100px;
            height: 100px;
        }
    }

    .header {
        &__total-cost {
            margin-top: em(5);
            font-size: em(12) !important;
        }
    }

    .load-more {
        display: flex;
        justify-content: center;
        padding: em(20) 0;

        &__btn {
            @include btn-primary;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .color-red {
        color: red;
    }

    .color-green {
        color: green;
    }
</style>

<Nav />
<Page>
    <div slot="header">
        <Header>
            <span slot="title">Admin</span>
            <div slot="subtitle">
                {#if !isLoading}
                    <div>{totalOrdersCount} {subtitle} {allOrders.length == 1 ? 'Order' : 'Orders'}</div>
                    <div class="header__total-cost">
                        {#if selectedCountry && selectedCountry.value && selectedCountry.value.length == 1}
                            {utilService.formatCurrency(totalCostForState, selectedCountry.value, selectedCountry.currencyCode)}
                        {/if}
                    </div>
                {/if}

            </div>
        </Header>
    </div>
    <div slot="content">
        <FullLayout>
            <div class="root">
                <div class="filters">
                    {#each values(ORDERS_CONFIG.states) as state, i}
                        <div
                            class="filters__filter"
                            class:filters__filter--active={params.state === state.key}
                            on:click={() => handleClickFilter(`/admin/orders/${state.key}`)}>
                            <a href={`/admin/orders/${state.key}`} use:link>{state.displayName}</a>
                        </div>
                    {/each}
                </div>
                <div class="content">
                    <div class="secondary-filters">
                        <div class="secondary-filters__left">
                            <div class="secondary-filter">
                                <div class="secondary-filter__label">Country</div>
                                <div class="secondary-filter__select">
                                    <Select
                                        items={countries}
                                        placeholder="Select Country"
                                        on:select={() => {
                                            loadOrders(selectedCountry.value);
                                        }}
                                        bind:selectedValue={selectedCountry}
                                        showIndicator={true}
                                        isClearable={false}
                                        showChevron={true} />
                                </div>
                            </div>
                        </div>
                        <div class="secondary-filters__right" />
                    </div>
                    <div class="orders">
                        <table class="orders-table">
                            <thead>
                                <tr>
                                    <th>Customer</th>
                                    <th>Products</th>
                                    <th>Details</th>
                                    <th>Notes</th>
                                    <th>State</th>
                                </tr>
                            </thead>
                            <tbody>
                                {#if allOrders.length == 0 && !isLoading}
                                    <tr class="animate__animated animate__fadeIn">
                                        <td colspan="8">
                                            <div class="message">
                                                <div>There are currently no {state.displayName} orders</div>
                                            </div>
                                        </td>
                                    </tr>
                                {/if}
                                {#if isLoading}
                                    <tr class="animate__animated animate__fadeIn">
                                        <td colspan="7">
                                            <div class="message">
                                                <div class="message__loader">
                                                    <Lottie jsonPath="lottie/products-loading-2.json" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                {/if}

                                {#each allOrders as order, i}
                                    <tr class:orders-table__hidden={order.state != state.id}>
                                        <td class="customer">
                                            <div class="customer__headline">
                                                <div class="customer__country">
                                                    <img
                                                        src={`/images/country_${order.countryId}_icon@2x.svg`}
                                                        height="20"
                                                        alt="country" />
                                                </div>
                                                <div class="customer__id">
                                                    {order.confirmationId ? order.confirmationId : 'N/A'}
                                                </div>
                                            </div>

                                            <div class="customer__name">
                                                {order.customer.firstName} {order.customer.lastName}
                                            </div>
                                            <div class="customer__employer">
                                                {order.customer.employer ? order.customer.employer : ''}
                                            </div>
                                            <div class="customer__contact">
                                                <div>
                                                    <a href={`mailto:${order.customer.emailAddress}`}>
                                                        {order.customer.emailAddress}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href={`tel:${order.customer.phoneNumber}`}>
                                                        {order.customer.phoneNumber}
                                                    </a>
                                                </div>
                                            </div>
                                            {#if order.customer.address}
                                                <div class="customer__address">
                                                    <div>{order.customer.address.street}</div>
                                                    <div>{order.customer.address.location.displayName}</div>
                                                    <div>{order.country}</div>
                                                </div>
                                            {/if}
                                            {#if order.customer.isExistingCustomer}
                                                <div>*Existing Customer</div>
                                            {/if}
                                        </td>
                                        <td class="products">
                                            <ul class="products__list">
                                                {#each order.products as product, i}
                                                    <li class="product">
                                                        <div class="product__retailer">{product.retailer}</div>
                                                        <div class="product__name">
                                                            {product.name}
                                                            <strong>x{product.quantity}</strong>
                                                        </div>
                                                        <div class="product__code">{product.code}</div>
                                                        <div class="product__price">
                                                            {utilService.formatCurrency(product.price, selectedCountry.value, selectedCountry.currencyCode)}
                                                        </div>
                                                    </li>
                                                {/each}
                                            </ul>
                                        </td>
                                        <td class="loan">
                                            <div class="loan__group">
                                                <div>Payment Type:</div>
                                                <div>
                                                    <strong>
                                                        {order.paymentType == ORDERS_CONFIG.paymentTypes.creditCard ? 'Credit Card / Cash' : 'Financing'}
                                                    </strong>
                                                </div>
                                            </div>
                                            {#if order.paymentType == ORDERS_CONFIG.paymentTypes.creditCard}
                                                <div class="loan__price">
                                                    <div>
                                                        Administration Fees:
                                                        <strong>
                                                            {utilService.formatCurrency(order.loan.totalInterestCharge, selectedCountry.value, selectedCountry.currencyCode)}
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        Delivery Fee:
                                                        <strong>
                                                            {utilService.formatCurrency(order.delivery ? order.formatted.deliveryFee : 0, selectedCountry.value, selectedCountry.currencyCode)}
                                                            <!-- {order.formatted ? order.formatted.deliveryFee : 'N/A'} -->
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        Total Cost of Product(s):
                                                        <strong>
                                                            {order.formatted ? order.formatted.totalCost : 'N/A'}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div class="loan__price">
                                                    {#if order.grandTotal}
                                                        <div>
                                                            Grand Total
                                                            <i>(incl. Admin Fees + Delivery)</i>
                                                            :
                                                            <strong>{order.formatted.grandTotal}</strong>
                                                        </div>
                                                    {:else}
                                                        <!-- Legacy -->
                                                        <div>
                                                            Grand Total
                                                            <i>(incl. Admin Fees + Delivery)</i>
                                                            :
                                                            <strong>
                                                                {utilService.formatCurrency(order.totalCost + order.loan.totalInterestCharge, selectedCountry.value, selectedCountry.currencyCode)}
                                                            </strong>
                                                        </div>
                                                    {/if}
                                                </div>
                                            {:else}
                                                <div class="loan__term">
                                                    <div>{order.loan.numPeriods} month(s)</div>
                                                    <div>{order.loan.startingMonth} - {order.loan.endingMonth}</div>
                                                </div>
                                                <div class="loan__price">
                                                    <div>
                                                        Monthly Payment:
                                                        <strong>
                                                            {utilService.formatCurrency(order.loan.monthlyPaymentAmount, selectedCountry.value, selectedCountry.currencyCode)}
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        Interest & Fees:
                                                        <strong>
                                                            {utilService.formatCurrency(order.loan.totalInterestCharge, selectedCountry.value, selectedCountry.currencyCode)}
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        Delivery Fee:
                                                        <strong>
                                                            {order.formatted ? order.formatted.deliveryFee : 'N/A'}
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        Total Cost of Product(s):
                                                        <strong>
                                                            {order.formatted ? order.formatted.totalCost : 'N/A'}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div class="loan__price">
                                                    {#if order.grandTotal}
                                                        <div>
                                                            Financed Amount
                                                            <i>(incl. Delivery)</i>
                                                            :
                                                            <strong>{order.formatted.grandTotal}</strong>
                                                        </div>
                                                    {:else}
                                                        <!-- Legacy -->
                                                        <div>
                                                            Financed Amount
                                                            <i>(incl. Delivery)</i>
                                                            :
                                                            <strong>
                                                                {utilService.formatCurrency(order.totalCost + order.deliveryFee, selectedCountry.value, selectedCountry.currencyCode)}
                                                            </strong>
                                                        </div>
                                                    {/if}
                                                </div>
                                                <div class="loan__group">
                                                    <div>Loan ID:</div>
                                                    <input
                                                        disabled={contains([ORDERS_CONFIG.states.approved.id, ORDERS_CONFIG.states.transit.id, ORDERS_CONFIG.states.delivered.id], order.state)}
                                                        class="loan__input"
                                                        type="text"
                                                        bind:value={order.loan.id}
                                                        on:blur={() => handleUpdateOrder(order)}
                                                        placeholder="Enter Loan ID" />
                                                </div>
                                            {/if}
                                            <div class="loan__group">
                                                <div>
                                                    <i
                                                        class="fa fa-solid"
                                                        class:color-green={order.loan.creditCapacity >= order.totalCost}
                                                        class:color-red={order.loan.creditCapacity < order.totalCost}
                                                        class:fa-triangle-exclamation={!order.loan.creditCapacity || order.loan.creditCapacity < order.totalCost}
                                                        class:fa-circle-check={order.loan.creditCapacity >= order.totalCost} />
                                                    <span />
                                                    Credit Capacity:
                                                </div>
                                                <input
                                                    disabled={contains([ORDERS_CONFIG.states.transit.id, ORDERS_CONFIG.states.delivered.id], order.state)}
                                                    class="loan__input"
                                                    type="text"
                                                    bind:value={order.formatted.creditCapacity}
                                                    on:focus={({ target: { value } }) => handleFocusCreditCapacity(value, order)}
                                                    on:blur={({ target: { value } }) => handleUpdateCreditCapacity(value, order)}
                                                    placeholder="Enter Credit Capacity" />
                                            </div>
                                            <div
                                                style="display: flex; flex-flow: row nowrap; gap: 0 20px; justify-items:
                                                center; align-items: center;">
                                                <div class="dates__group">
                                                    Date Created:
                                                    <div>
                                                        <strong>
                                                            {utilService.getDateFromTimestamp(order.dateCreated)}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div class="dates__group">
                                                    Date Modified
                                                    <div>
                                                        <strong>
                                                            {utilService.getDateFromTimestamp(order.dateModified) || 'N/A'}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div class="dates__group">
                                                    <div>Delivery Date:</div>
                                                    <Datepicker
                                                        format={'#{m}-#{d}-#{Y}'}
                                                        on:dateSelected={() => handleUpdateOrder(order)}
                                                        bind:dateChosen={order.dateScheduledConfirmed}
                                                        bind:formattedSelected={order.formatted.dateScheduled}
                                                        bind:selected={order.dateScheduled}>
                                                        <div class="dates__picker">
                                                            {#if order.dateScheduled && order.dateScheduledConfirmed}
                                                                {order.formatted.dateScheduled}
                                                            {:else}Choose Date{/if}
                                                        </div>
                                                    </Datepicker>
                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            <div class="notes">
                                                <textarea
                                                    class="notes__textarea"
                                                    placeholder="Enter Notes"
                                                    bind:value={order.notes}
                                                    on:blur={() => handleUpdateOrder(order)} />

                                            </div>
                                        </td>
                                        <td class="state">
                                            <div style="margin-bottom: 25px;">
                                                <div style="margin-bottom: 5px;">State:</div>
                                                <div>
                                                    <Select
                                                        items={orderStateOptions}
                                                        selectedValue={Object.assign({}, findWhere(orderStateOptions, {
                                                                value: order.state,
                                                            }))}
                                                        on:select={(event) => {
                                                            handleUpdateState(event, order).then( () => {
                                                                    order.state = event.detail.value;
                                                                }, (err) => {
                                                                    order.state = order.state;
                                                                }, );
                                                        }}
                                                        placeholder="Select State"
                                                        isClearable={false}
                                                        showIndicator={true}
                                                        showChevron={true} />
                                                </div>
                                            </div>
                                            <div style="margin-bottom: 25px;">
                                                <div style="margin-bottom: 5px;">Internal Status:</div>
                                                <div>
                                                    <Select
                                                        items={internalStateOptions}
                                                        selectedValue={Object.assign({}, findWhere(
                                                                internalStateOptions,
                                                                {
                                                                    value: order.internalState
                                                                        ? order.internalState
                                                                        : ORDERS_CONFIG.internalStates.newOrder.id,
                                                                },
                                                            ))}
                                                        on:select={(event) => {
                                                            handleUpdateInternalState(event, order).then( () => {
                                                                    order.internalState = event.detail.value;
                                                                }, (err) => {
                                                                    order.internalState = order.internalState;
                                                                }, );
                                                        }}
                                                        placeholder="Select Internal Status"
                                                        isClearable={false}
                                                        showIndicator={true}
                                                        showChevron={true} />
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        displayActivity(order);
                                                    }}>
                                                    View Activity
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                {/each}
                                {#if hasMoreOrders}
                                    <tr>
                                        <td colspan="7">
                                            <div class="load-more">
                                                <button
                                                    class="load-more__btn"
                                                    disabled={isLoading}
                                                    on:click={loadMoreOrders}>
                                                    {isLoading ? 'Loading...' : 'Load More Orders'}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                {/if}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </FullLayout>
    </div>
</Page>
