import db from 'services/db';
import user from 'stores/user';

export async function authenticate(id) {
    const userRef = await db.collection('users').doc(id).get();
    if (userRef.exists) {
        user.setProfile({ id, ...userRef.data() }); // Update the store directly from the service
        return userRef.data();
    } else {
        return null;
    }
}

export async function logout() {
    return firebase.auth().signOut();
}

export default {
    authenticate,
    logout,
};
