<script>
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    import Select from 'svelte-select';

    import shop from 'stores/shop';
    import user from 'stores/user';
    import SHOP_CONFIG from 'configs/shop';

    import Button from 'components/Button';

    const dispatch = createEventDispatcher();

    let retailerFilter, sortFilter, categoryFilter;
    let previousFilters = null;

    const unsubscribe = user.subscribe((user) => {
        clearFilters();
    });

    onDestroy(() => {
        unsubscribe();
    });

    function getRetailerOptions() {
        return $shop.retailers.map((retailer) => {
            return {
                label: retailer.displayName,
                value: retailer.id,
            };
        });
    }

    function handleFilterSelect() {
        let filters = {
            [SHOP_CONFIG.filters.keys.retailer]: retailerFilter
                ? retailerFilter.map((retailer) => retailer.value)
                : null,
            [SHOP_CONFIG.filters.keys.category]: categoryFilter ? categoryFilter.value : null,
            [SHOP_CONFIG.filters.keys.sort]: sortFilter ? sortFilter.value : null,
        };

        dispatch('filterSelect', filters);
    }

    function clearFilters() {
        retailerFilter = undefined;
        sortFilter = undefined;
        categoryFilter = undefined;
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .filters {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 0.33fr));
        grid-gap: em(15);
        justify-content: flex-start;
        align-items: center;
        margin: em(40) 0;

        @include for-tablet-landscape-up {
            grid-template-columns: repeat(auto-fit, minmax(200px, 0.33fr));
            grid-gap: em(25);
        }

        @include for-desktop-up {
            margin: em(20) 0 em(40) 0;
        }
    }

    .filter {
        width: 100%;

        &__label {
            margin-bottom: em(10);
            display: block;
            font-size: em(16);
            font-weight: $fw-bold;
        }

        &__select {
            width: 100%;
            font-size: em(14);
            --border: none;
            --borderRadius: 10px;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #822c7d;
            --multiItemActiveBG: #166f86;
            --multiClearBG: #999999;
        }
    }
</style>

<div class="filters">
    <div class="filter">
        <label class="filter__label">Retailer</label>
        <div class="filter__select">
            <Select
                items={$shop.retailers ? getRetailerOptions() : []}
                bind:selectedValue={retailerFilter}
                on:select={handleFilterSelect}
                isMulti
                placeholder="All Retailers"
                showChevron={true} />
        </div>
    </div>
    <div class="filter">
        <label class="filter__label">Category</label>
        <div class="filter__select">
            <Select
                items={SHOP_CONFIG.filters.options.categories}
                on:select={handleFilterSelect}
                on:clear={handleFilterSelect}
                bind:selectedValue={categoryFilter}
                placeholder="All Categories"
                showChevron={true} />
        </div>
    </div>
    <!-- <div class="filter">
        <label class="filter__label">Sort</label>
        <div class="filter__select">
            <Select
                items={SHOP_CONFIG.filters.options.sort}
                on:select={handleFilterSelect}
                bind:selectedValue={sortFilter}
                placeholder="Default Sort Order"
                showChevron={true} />
        </div>
    </div> -->
</div>
