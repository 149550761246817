<script>
    import { onMount } from 'svelte';

    import shoppingCart from 'stores/shoppingCart';

    import Nav from 'components/Nav';
    import Page from 'components/Page';
    import Header from 'components/Header';
    import SplitLayout from 'components/SplitLayout';
    import ShoppingCartBreakdown from 'components/ShoppingCartBreakdown';
    import ProductList from 'components/ProductList';

    let products = [];

    onMount(() => {
        shoppingCart.clearLoan();
        shoppingCart.clearCustomer();
        shoppingCart.clearDelivery();
    });
</script>

<style lang="scss">
    @import 'sass/base';
</style>

<div class="root">
    <Nav />
    <Page>
        <div slot="header">
            <Header canSeeBack>
                <span slot="title">My Cart</span>
                <span slot="subtitle">
                    {`${$shoppingCart.numProducts} ${$shoppingCart.numProducts == 1 ? 'Product' : 'Products'} Ready for Checkout`}
                </span>
                <span slot="back">Browse Products</span>
            </Header>
        </div>
        <div slot="content">
            <SplitLayout>
                <div slot="left">
                    <ProductList products={$shoppingCart.products} />
                </div>
                <div slot="right">
                    <ShoppingCartBreakdown />
                </div>
            </SplitLayout>
        </div>
    </Page>

</div>
