import { get } from 'svelte/store';
import { wrap, push } from 'svelte-spa-router';

import user from 'stores/user';
import shoppingCart from 'stores/shoppingCart';

import USERS_CONFIG from 'configs/users';

import ProductsPage from 'pages/ProductsPage';
import ProductsPageV2 from 'pages/ProductsPageV2';
import ReviewCartPage from 'pages/ReviewCartPage';
import CheckoutPage from 'pages/CheckoutPage';
import LoginPage from 'pages/Admin/LoginPage';
import OrdersPage from 'pages/Admin/OrdersPage';
import AdminProductsPage from 'pages/Admin/ProductsPage';
import RetailersPage from 'pages/Admin/RetailersPage';

function isCountrySelected() {
    let userStore = get(user);
    return userStore && userStore.country;
}

function isShoppingCartFilled() {
    let shoppingCartStore = get(shoppingCart);
    return shoppingCartStore.products.length;
}

function isAuthenticated() {
    return firebase.auth().currentUser != null;
}

function isAdmin(detail) {
    let userStore = get(user);
    return (
        userStore.profile.role == USERS_CONFIG.roles.superAdmin ||
        userStore.profile.role == USERS_CONFIG.roles.countryAdmin
    );
}

const routes = {
    '/': ProductsPageV2,
    '/retailer/:retailerId': ProductsPageV2,
    '/cart': wrap(
        ReviewCartPage,
        {
            onConditionsFailed: () => {
                push('/');
            },
        },
        isCountrySelected,
        isShoppingCartFilled,
    ),
    '/checkout': wrap(
        CheckoutPage,
        {
            onConditionsFailed: () => {
                push('/');
            },
        },
        isCountrySelected,
        isShoppingCartFilled,
    ),
    '/admin': LoginPage,
    '/admin/orders/:state': wrap(
        OrdersPage,
        {
            onConditionsFailed: () => {
                push('/admin');
            },
        },
        isAuthenticated,
        isAdmin,
    ),
    '/admin/products': wrap(
        AdminProductsPage,
        {
            onConditionsFailed: () => {
                push('/admin');
            },
        },
        isAuthenticated,
    ),
    '/admin/retailers': wrap(
        RetailersPage,
        {
            onConditionsFailed: () => {
                push('/admin');
            },
        },
        isAuthenticated,
        isAdmin,
    ),
};

export default routes;
