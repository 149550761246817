const storage = firebase.storage();

export async function upload(bucket, filename, ext, file) {
    let storageRef = storage.ref().child(`${bucket}/${filename}.${ext}`);

    await storageRef
        .put(file, {
            contentType: `image/${ext}`,
        })
        .catch((err) => {
            throw {
                message: `Sorry, we were unable to process your request. Please try again.`,
                code: err.code,
            };
        });

    return storageRef.getDownloadURL();
}

export default {
    upload,
};
