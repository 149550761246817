<script>
    import { fade } from 'svelte/transition';

    import ProductListItem from './ProductListItem';

    export let products = [];
</script>

<style lang="scss">
    @import 'sass/base';

    .list {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        &__item {
            width: 100%;
        }
    }
</style>

<ul class="list">
    {#each products as product, i}
        <li class="list__item" in:fade={{ duration: 250, delay: i * 100 }}>
            <ProductListItem {product} />
        </li>
    {/each}
</ul>
