import { writable } from 'stores/localStorage';
import ORDERS_CONFIG from 'configs/orders';
import { without } from 'underscore';

const key = 'shoppingCart';
const initialValue = {
    paymentType: ORDERS_CONFIG.paymentTypes.finance,
    customer: {
        address: {},
    },
    products: [],
    numProducts: 0,
    totalCost: 0,
    loan: {},
    delivery: {
        isIncluded: true,
        fee: null,
    },
};

function getCartTotal(products) {
    let total = 0;
    products.forEach((product) => {
        total += parseInt(product.salePrice || product.price, 10) * parseInt(product.quantity, 10);
    });

    return total;
}

function createStore() {
    const { subscribe, set, update, get } = writable(key, initialValue);

    return {
        subscribe,

        add: (product) => {
            update((store) => {
                let existingProduct = store.products.find((p) => p.id == product.id);
                if (existingProduct) {
                    existingProduct.quantity += 1;
                } else {
                    product.quantity = 1;
                    store.products.push(product);
                }

                store.totalCost = getCartTotal(store.products);
                store.numProducts += 1;

                return store;
            });
        },

        remove: (product, removeAll = false) => {
            update((store) => {
                if (removeAll) {
                    store.products = without(store.products, product);
                    store.numProducts -= product.quantity;
                } else {
                    let existingProduct = store.products.find((p) => p.id == product.id);

                    if (existingProduct && existingProduct.quantity > 1) {
                        existingProduct.quantity -= 1;
                    } else {
                        store.products = without(store.products, product);
                    }

                    store.numProducts -= 1;
                }

                store.totalCost = getCartTotal(store.products); // Does not include taxes or fees

                return store;
            });
        },

        setPaymentType: (paymentType) => {
            update((store) => {
                return {
                    ...store,
                    paymentType,
                };
            });
        },

        setLoan: (loan) => {
            update((store) => {
                return {
                    ...store,
                    loan,
                };
            });
        },

        clearLoan: () => {
            update((store) => {
                return {
                    ...store,
                    paymentType: null,
                    loan: {},
                };
            });
        },

        setCustomer: (customer) => {
            update((store) => {
                return {
                    ...store,
                    customer,
                };
            });
        },

        clearCustomer: () => {
            update((store) => {
                return {
                    ...store,
                    customer: {
                        address: {},
                    },
                };
            });
        },

        setDelivery: (isDeliveryIncluded, deliveryFee = null) => {
            update((store) => {
                return {
                    ...store,
                    delivery: {
                        isIncluded: isDeliveryIncluded,
                        fee: deliveryFee,
                    },
                };
            });
        },

        clearDelivery: () => {
            update((store) => {
                return {
                    ...store,
                    delivery: {
                        isIncluded: true,
                        fee: null,
                    },
                };
            });
        },

        clear: () => {
            set({
                paymentType: ORDERS_CONFIG.paymentTypes.finance,
                products: [],
                totalCost: 0,
                numProducts: 0,
                loan: {},
                customer: {
                    address: {},
                },
                delivery: {
                    isIncluded: true,
                    fee: null,
                },
            });
        },
    };
}

export default createStore();
