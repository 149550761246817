import { get } from 'svelte/store';
import user from 'stores/user';

import db from 'services/db';
import ORDERS_CONFIG from 'configs/orders';
import { convertDocsToArray, generateRetailerId, sanitizeObject } from 'services/util';

export async function getOrdersByState(
    stateId,
    countryIds = [],
    {
        pageSize = null,
        lastDocSnap = null, // Pass this for pagination
    } = {},
) {
    let ordersRef = db.collection('orders');

    ordersRef = ordersRef
        .where('state', '==', stateId)
        .where('countryId', 'in', countryIds)
        .orderBy('dateCreated', 'desc');

    // Apply cursor-based pagination if lastDocSnap is provided
    if (lastDocSnap) {
        ordersRef = ordersRef.startAfter(lastDocSnap);
    }

    if (pageSize) {
        ordersRef = ordersRef.limit(pageSize);
    } else if (stateId == ORDERS_CONFIG.states.cancelled.id) {
        ordersRef = ordersRef.limit(50);
    } else if (stateId == ORDERS_CONFIG.states.delivered.id) {
        ordersRef = ordersRef.limit(100);
    }

    const docs = await ordersRef.get();

    return {
        orders: convertDocsToArray(docs),
        lastDoc: docs.docs[docs.docs.length - 1] || null,
        hasMore: docs.docs.length === (pageSize || 50), // Use default of 50 if pageSize not specified
    };
}

export async function getOrdersAggregation(stateId, countryIds = []) {
    const ordersRef = db.collection('orders');

    const query = ordersRef.where('state', '==', stateId).where('countryId', 'in', countryIds);

    try {
        const snapshot = await query.get();

        // Calculate count and sum manually
        const count = snapshot.size;
        const totalAmount = snapshot.docs.reduce((sum, doc) => {
            const data = doc.data();
            return sum + (data.totalCost || 0);
        }, 0);

        return {
            count,
            totalAmount,
        };
    } catch (error) {
        console.error('Error getting aggregation:', error);
        return {
            count: 0,
            totalAmount: 0,
        };
    }
}

export async function getOrderActivity(orderId) {
    try {
        const activities = await db
            .collection('orders')
            .doc(orderId)
            .collection('activity')
            .orderBy('timestamp', 'desc')
            .get();

        let _activities = activities.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        // This will clean up a bug with duplicating activities
        return _activities.filter((activity) => {
            return activity.fromState != activity.toState;
        });
    } catch (error) {
        console.error('Error getting order activity:', error);
        throw error;
    }
}

export async function updateOrderState(order, newStateId) {
    let orderRef = db.collection('orders').doc(order.id);

    const userStore = get(user);

    try {
        // Create a timestamp to use in both operations
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();

        // Execute both operations in a batch to ensure atomicity
        const batch = db.batch();

        // Update the order's state
        batch.update(orderRef, {
            dateModified: timestamp,
            state: newStateId,
        });

        // Create the activity record in the subcollection
        if (order.state != newStateId) {
            const activityRef = orderRef.collection('activity').doc(); // Auto-generated ID
            batch.set(activityRef, {
                type: 'state_change',
                fromState: order.state ? order.state : null,
                toState: newStateId,
                timestamp: timestamp,
                user: {
                    id: userStore.profile.id,
                    displayName: `${userStore.profile.firstName} ${userStore.profile.lastName}`,
                },
                automated: false, // Useful to distinguish between system and user changes
            });
        }

        // Commit both operations
        return await batch.commit();
    } catch (error) {
        console.error('Error updating order state:', error);
        throw error;
    }
}

export async function updateOrderInternalState(order, newStateId) {
    let orderRef = db.collection('orders').doc(order.id);
    const userStore = get(user);

    try {
        // Create a timestamp to use in both operations
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();

        // Execute both operations in a batch to ensure atomicity
        const batch = db.batch();

        // Update the order's state
        batch.update(orderRef, {
            dateModified: timestamp,
            internalState: newStateId,
        });

        // Create the activity record in the subcollection
        if (order.internalState != newStateId) {
            const activityRef = orderRef.collection('activity').doc(); // Auto-generated ID
            batch.set(activityRef, {
                type: 'internal_state_change',
                fromState: order.internalState ? order.internalState : null,
                toState: newStateId,
                timestamp: timestamp,
                user: {
                    id: userStore.profile.id,
                    displayName: `${userStore.profile.firstName} ${userStore.profile.lastName}`,
                },
                automated: false, // Useful to distinguish between system and user changes
            });
        }

        // Commit both operations
        return await batch.commit();
    } catch (error) {
        console.error('Error updating order state:', error);
        throw error;
    }
}

export async function updateOrderLoanId(order) {
    let orderRef = db.collection('orders').doc(order.id);

    return orderRef.update({
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        'loan.id': order.loan.id || null,
    });
}

export async function updateOrder(order) {
    let orderRef = db.collection('orders').doc(order.id);

    return orderRef.update({
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        dateScheduled: order.dateScheduledConfirmed ? order.dateScheduled : null,
        notes: order.notes || null,
        'loan.id': order.loan.id || null,
        'loan.creditCapacity': parseInt(order.loan.creditCapacity, 10) || null,
        'formatted.creditCapacity': order.formatted.creditCapacity || null,
        'formatted.dateScheduled': order.formatted.dateScheduled || null,
    });
}

export async function getRetailers(countryId, allowedRetailers = null) {
    let retailersRef = db.collection('retailers');

    retailersRef = retailersRef.where('countryId', '==', countryId);

    if (allowedRetailers) {
        retailersRef = retailersRef.where(firebase.firestore.FieldPath.documentId(), 'in', allowedRetailers);
    }

    const docs = await retailersRef.get();
    return convertDocsToArray(docs);
}

export async function getCountries(allowedCountries = null) {
    let countriesRef = db.collection('countries');

    if (allowedCountries) {
        countriesRef = countriesRef.where(firebase.firestore.FieldPath.documentId(), 'in', allowedCountries);
    }

    const docs = await countriesRef.get();

    return convertDocsToArray(docs);
}

export async function getProductsByRetailer(retailer) {
    let productsRef = db.collection('products');

    productsRef = productsRef.where('retailerId', '==', retailer).orderBy('dateCreated', 'desc');

    const docs = await productsRef.get();
    return convertDocsToArray(docs);
}

export async function updateProduct(product) {
    let productRef = db.collection('products').doc(product.id);

    let { name, specs, code, price, retailerId, retailerPrice, metadata, inStock, isFeatured, imageUrl } = product;

    return productRef.update({
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        name,
        imageUrl,
        code,
        specs,
        desc: product.desc || null,
        price,
        inStock: inStock,
        isFeatured: isFeatured || false,
        'metadata.keywords': metadata.keywords,
        salePrice: product.salePrice || null,
        retailerId,
        retailerPrice,
    });
}

export async function addProduct(product) {
    let productRef = db.collection('products');

    return productRef.add({
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        ...product,
    });
}

export async function saveRetailer(retailer) {
    let retailerRef;

    if (!retailer.id) {
        let id = generateRetailerId(retailer.displayName, retailer.countryId);
        retailerRef = db.collection('retailers').doc(id);
        return retailerRef.set({
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            ...retailer,
        });
    } else {
        retailerRef = db.collection('retailers').doc(retailer.id);
        let { displayName, isActive, countryId, logoUrl } = retailer;

        retailerRef.update({
            dateModified: firebase.firestore.FieldValue.serverTimestamp(),
            displayName,
            isActive: isActive ? true : false,
            countryId,
            logoUrl: logoUrl ? logoUrl : null,
        });
    }
}

export async function removeProduct(productId) {
    let productRef = db.collection('products').doc(productId);
    return productRef.delete();
}

export default {
    getOrdersByState,
    getOrderActivity,
    getOrdersAggregation,
    updateOrderState,
    updateOrderInternalState,
    updateOrderLoanId,
    updateOrder,
    getRetailers,
    getCountries,
    getProductsByRetailer,
    addProduct,
    updateProduct,
    removeProduct,
    saveRetailer,
};
