<script>
    import { pop } from 'svelte-spa-router';
    import Container from 'components/Container';
    import Lottie from 'components/Lottie';
    import AnimatedGrid from 'components/AnimatedGrid';

    export let canSeeBack = false;
</script>

<style lang="scss">
    @import 'sass/base';

    .header {
        position: relative;
        overflow: hidden;
        padding: em(50) 0;
        background: $color-gray-light;
        background: url('/images/header_bg_mobile@2x.jpg') no-repeat top right;
        background-size: cover;

        @include for-tablet-portrait-up {
            background: url('/images/header_bg@2x.jpg') no-repeat top right;
            background-size: cover;
        }

        @include for-desktop-up {
            height: 400px;
        }

        &__title {
            position: relative;
            z-index: 2;
            font-size: em(56);
            color: $color-black;
            font-family: $ff-headers;
            font-weight: $fw-bold;
            line-height: 1;

            @include for-tablet-portrait-up {
                font-size: em(72);
            }
        }

        &__subtitle {
            position: relative;
            z-index: 2;
            margin-top: em(5);
            font-size: em(18);
            color: $color-gray-text;
            font-weight: $fw-regular;
            text-transform: capitalize;

            @include for-tablet-portrait-up {
                font-size: em(24);
            }
        }

        &__grid {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .header-back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 3;
        margin-top: em(30);

        &__label {
            display: inline-block;
            margin-left: em(10);
            font-size: em(14);
            color: $color-gray-text;
            font-weight: $fw-regular;
            transition: 0.25s all ease-in;

            &:hover {
                color: $color-primary;
            }
        }

        &__icon {
            display: inline-block;
        }
    }
</style>

<header class="header">
    <div class="header__grid">
        <AnimatedGrid />
    </div>
    <Container>
        <h1 class="header__title">
            <slot name="title" />
        </h1>
        <h2 class="header__subtitle">
            <slot name="subtitle" />
        </h2>
        {#if canSeeBack}
            <div
                class="header-back"
                on:click={() => {
                    pop();
                }}>
                <img src="images/back_arrow_icon@2x.svg" class="header-back__icon" height="25" alt="back" />
                <div class="header-back__label">
                    <slot name="back" />
                </div>
            </div>
        {/if}
    </Container>
</header>
