<script>
    import { onMount } from 'svelte';
    import { tweened } from 'svelte/motion';
    import { cubicOut } from 'svelte/easing';
    import Select from 'svelte-select';

    import ORDERS_CONFIG from 'configs/orders';

    import shoppingCart from 'stores/shoppingCart';
    import user from 'stores/user';

    import { getLocationsByCountryId } from 'services/user';

    import { formatCurrency } from 'services/util';
    import { calculateLoan } from 'services/loan';

    let totalInterestCharge = tweened($shoppingCart.loan.totalInterestCharge || 0, {
        duration: 400,
        easing: cubicOut,
    });

    let monthlyPaymentAmount = tweened($shoppingCart.loan.monthlyPayment || 0, {
        duration: 400,
        easing: cubicOut,
    });

    let paymentType = ORDERS_CONFIG.paymentTypes.finance;
    let firstName, lastName, emailAddress, phoneNumber, employer;
    let isExistingCustomer = false;
    let address = {};

    let locations = [];
    let numPeriodsAvailable = 36;
    let numPeriods = 12;

    $: {
        shoppingCart.setCustomer({
            firstName,
            lastName,
            emailAddress,
            employer,
            phoneNumber,
            address,
            isExistingCustomer,
        });

        shoppingCart.setPaymentType(paymentType);
    }

    onMount(() => {
        calculate();
        loadLocations();
    });

    function handlePeriodSelect(numPeriodsSelected) {
        numPeriods = numPeriodsSelected;
        calculate();
    }

    function handlePaymentTypeSelect(pt) {
        paymentType = pt;
        calculate();
    }

    function loadLocations() {
        getLocationsByCountryId($user.country.id).then((data) => {
            locations = data.map((location) => {
                return {
                    label: location.displayName,
                    value: location.id,
                    deliveryFee: location.deliveryFee,
                };
            });
        });
    }

    function handleLocationSelect(event) {
        let data = event.detail;

        shoppingCart.setDelivery(true, data.deliveryFee);
        address.location = {
            displayName: data.label,
            id: data.value,
        };
    }

    function calculate() {
        // Note: Total Cost DOES NOT include Delivery Fee or Interest Fees
        // let totalCost = $shoppingCart.totalCost + ($shoppingCart.delivery.isIncluded ? $shoppingCart.delivery.fee : 0);
        let totalCost = $shoppingCart.totalCost;
        shoppingCart.setLoan(calculateLoan($user.country, paymentType, totalCost, numPeriods));
        totalInterestCharge.set($shoppingCart.loan.totalInterestCharge);
        monthlyPaymentAmount.set($shoppingCart.loan.monthlyPaymentAmount);
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .checkout {
        margin: 0;
        padding: em(25);
    }

    .form {
        margin: 0;
        padding: 0;
    }

    .group {
        width: 100%;
        margin-bottom: em(40);
    }

    .group-heading {
        margin-bottom: em(25);
        padding-bottom: em(10);
        border-bottom: 2px solid $color-gray-dark;

        &__title {
            margin-bottom: em(10);
            font-size: em(18);
            font-weight: $fw-bold;
            color: $color-black;
        }

        &__subtitle {
            font-size: em(14);
            color: $color-gray-text;
        }
    }

    .sub-group {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .control {
        margin: em(30) 0;

        &__label {
            display: block;
            font-size: em(16);
        }

        &__big {
            margin: em(8) 0;
            font-size: em(32);
            font-weight: $fw-bold;
            color: $color-primary;
        }
    }

    .field {
        margin-bottom: em(25);

        &--split {
            width: 100%;
        }

        &__label {
            display: block;
            margin-bottom: em(8);
            font-size: em(14);
        }

        &__input {
            width: 100%;
            border: 2px solid $color-gray-dark;
            padding: em(15) em(10);
            border-radius: 4px;
            outline: 0;
            font-size: em(14);
            font-weight: $fw-bold;
            transition: all 0.25s ease;

            @include for-tablet-portrait-up {
                width: 75%;

                &--split {
                    width: 95%;
                }
            }

            @include for-tablet-landscape-up {
                width: 50%;

                &--split {
                    width: 95%;
                }
            }

            &:hover {
                border: 2px solid $color-primary;
            }
        }

        &__select {
            width: 50%;
            font-size: em(14);
            --border: 2px solid #e8eae9;
            --borderRadius: 4px;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #822c7d;
            --multiItemActiveBG: #166f86;
            --multiClearBG: #999999;
        }

        &__checkbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row nowrap;

            div {
                margin-left: em(8);
            }
        }
    }

    .radio-buttons {
        margin-top: em(25);

        &__rb {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            margin-bottom: em(10);
            padding: em(10);
            border: 2px solid $color-gray-dark;
            transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

            &:hover {
                @extend .radio-buttons__rb--active;
            }

            &--active {
                border: 2px solid $color-primary;
            }
        }

        &__label {
            cursor: pointer;
            display: block;
        }

        &__title {
            margin-bottom: em(5);
            font-size: em(16);
        }

        &__desc {
            font-size: em(12);
            color: $color-gray-text;
        }

        &__btn {
            display: inline-block;
            margin-right: em(15);
        }
    }

    .periods {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
        gap: em(10) em(5);
        margin: em(25) 0;

        @include for-tablet-portrait-up {
            grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
        }

        &__period {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            background: $color-white;
            border-radius: 50%;
            font-size: em(14);
            color: $color-gray-text;
            text-align: center;
            cursor: pointer;

            @include for-tablet-portrait-up {
                height: 40px;
                font-size: em(18);
            }

            &:hover {
                background: $color-gray-light;
                animation: pulse;
                animation-duration: 0.5s;
            }

            &--selected {
                background: $color-primary;
                font-weight: $fw-bold;
                color: $color-white;

                &:hover {
                    background: $color-primary;
                }
            }
        }
    }

    .payments {
        overflow: hidden;
        margin: em(25) 0;
    }

    .payments-table {
        width: 100%;
        border: none;
        border-collapse: collapse;
        border-spacing: 0;

        &__thead {
            border-bottom: 2px solid $color-gray-dark;
        }

        &__th {
            padding: em(15) 0;
            font-size: em(14);
            font-weight: $fw-bold;
            text-align: left;
        }

        &__tr {
            border-bottom: 1px solid $color-gray-dark;
        }

        &__td {
            padding: em(15) 0;
            font-size: em(12);
            color: $color-gray-text;

            &--payment {
                color: $color-primary;
                font-weight: $fw-bold;
            }
        }
    }

    .split {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        @include for-tablet-portrait-up {
            flex-flow: row nowrap;
        }
    }
</style>

<div class="checkout">
    <form name="form">
        <div class="group">
            <div class="group-heading">
                <div class="group-heading__title">Choose your payment type</div>
                <div class="group-heading__subtitle">Pay for your products by credit card or financing.</div>
            </div>
            <div class="control">
                <label class="control__label">How would you like to pay?</label>
                <div class="radio-buttons">
                    <div
                        on:click={() => handlePaymentTypeSelect(ORDERS_CONFIG.paymentTypes.finance)}
                        class="radio-buttons__rb"
                        class:radio-buttons__rb--active={paymentType == ORDERS_CONFIG.paymentTypes.finance}>
                        <img
                            alt="payment type icon"
                            class="radio-buttons__btn"
                            src={paymentType == ORDERS_CONFIG.paymentTypes.finance ? '/images/checkmark_circle_icon.svg' : '/images/empty_circle_icon.svg'}
                            height="30" />
                        <label class="radio-buttons__label">
                            <div class="radio-buttons__title">Finance it!</div>
                            <div class="radio-buttons__desc">Pay over time with Term Finance</div>
                        </label>
                    </div>
                    <div
                        on:click={() => handlePaymentTypeSelect(ORDERS_CONFIG.paymentTypes.creditCard)}
                        class="radio-buttons__rb"
                        class:radio-buttons__rb--active={paymentType == ORDERS_CONFIG.paymentTypes.creditCard}>
                        <img
                            alt="payment type icon"
                            class="radio-buttons__btn"
                            src={paymentType == ORDERS_CONFIG.paymentTypes.creditCard ? '/images/checkmark_circle_icon.svg' : '/images/empty_circle_icon.svg'}
                            height="30" />
                        <label class="radio-buttons__label">
                            <div class="radio-buttons__title">Cash, Credit Card or Visa Debit</div>
                            <!-- <div class="radio-buttons__desc">FCB, RBC and Scotiabank cards accepted</div> -->
                        </label>
                    </div>
                </div>
            </div>
        </div>
        {#if paymentType == ORDERS_CONFIG.paymentTypes.finance}
            <div class="group">
                <div class="group-heading">
                    <div class="group-heading__title">Choose your payment plan</div>
                    <div class="group-heading__subtitle">
                        Convert your purchases into loans and spread your payments over time.
                    </div>
                </div>
                <div class="control">
                    <label class="control__label">Over how many months would you like to finance your purchase?</label>
                    <div class="periods">
                        {#each Array(numPeriodsAvailable) as _, i}
                            <div
                                class="periods__period"
                                class:periods__period--selected={i + 1 == numPeriods}
                                on:click={() => {
                                    handlePeriodSelect(i + 1);
                                }}>
                                {i + 1}
                            </div>
                        {/each}
                    </div>
                </div>
            </div>
            <div class="group">
                <div class="group-heading">
                    <div class="group-heading__title">Monthly Payment</div>
                    <div class="group-heading__subtitle">
                        Your calculated monthly payment based on the number of months you selected.
                    </div>
                </div>
                <div class="control">
                    <div class="control__big">
                        {formatCurrency($monthlyPaymentAmount, $user.country.id, $user.country.currencyCode)}
                    </div>
                    <div class="control__label">
                        {numPeriods} monthly payment(s) starting in
                        <strong>{$shoppingCart.loan.startingMonth}</strong>
                        and ending in
                        <strong>{$shoppingCart.loan.endingMonth}</strong>
                    </div>
                </div>
            </div>
        {/if}
        <div class="group">
            <div class="group-heading">
                <div class="group-heading__title">Customer Details</div>
                <div class="group-heading__subtitle">Please tell us a little bit about yourself.</div>
            </div>
            <div class="control">
                <div class="split">
                    <div class="field field--split">
                        <label class="field__label field__label--split">First Name</label>
                        <input class="field__input field__input--split" name="firstName" bind:value={firstName} />
                    </div>
                    <div class="field field--split">
                        <label class="field__label field__label--split">Last Name</label>
                        <input class="field__input field__input--split" name="lastName" bind:value={lastName} />
                    </div>
                </div>
                <div class="split">
                    <div class="field field--split">
                        <label class="field__label field__label--split">Email Address</label>
                        <input class="field__input field__input--split" name="emailAddress" bind:value={emailAddress} />
                    </div>
                    <div class="field field--split">
                        <label class="field__label field__label--split">Phone Number</label>
                        <input
                            class="field__input field__input--split"
                            name="phoneNumber"
                            type="tel"
                            bind:value={phoneNumber} />
                    </div>
                </div>
                <div class="split">
                    <div class="field field--split">
                        <label class="field__label field__label--split">Employer</label>
                        <input class="field__input field__input--split" name="employer" bind:value={employer} />
                    </div>
                    <div class="field field--split">
                        <div class="field__checkbox">
                            <input
                                type="checkbox"
                                bind:checked={isExistingCustomer}
                                on:change={(event) => {
                                    shoppingCart.setCustomer({ ...$shoppingCart.customer, isExistingCustomer });
                                }} />
                            <div>I am an existing Term Finance customer</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="group">
            <div class="group-heading">
                <div class="group-heading__title">Customer Address</div>
                <div class="group-heading__subtitle">This information will be used to calculate delivery fees.</div>
            </div>
            <div class="control">
                <div class="field">
                    <label class="field__label">Street</label>
                    <input class="field__input" name="street" bind:value={address.street} />
                </div>
                <div class="field">
                    <label class="field__label">City/Town</label>
                    <div class="field__select">
                        <Select
                            items={locations}
                            on:select={handleLocationSelect}
                            placeholder="Select Location"
                            showChevron={true} />
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
