<script>
    import { getContext } from 'svelte';
    import Button from 'components/Button';

    const { close } = getContext('simple-modal');

    export let title = 'Are you sure?';
    export let message;
    export let confirmLabel = 'OK';
    export let cancelLabel = 'Cancel';
    export let onConfirm = () => {};
    export let onCancel = () => {};

    function handleConfirm() {
        onConfirm();
        close();
    }

    function handleCancel(e) {
        e.preventDefault();
        onCancel();
        close();
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .modal-content {
        margin-bottom: em(60);
        padding: em(45) em(45) 0 em(45);

        &__title {
            margin-bottom: em(25);
            font-size: em(20);
            font-weight: $fw-bold;
            color: $color-primary;
        }

        &__desc {
            font-size: em(16);
            color: $color-gray-text;
            line-height: 1.25;
        }

        :global(p) {
            margin-bottom: em(25);
        }
    }

    .actions {
        padding: em(10) em(25);
        background: $color-gray-light;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        width: auto;

        @include for-tablet-landscape-up {
            flex-flow: row nowrap;
            justify-content: flex-end;
        }
    }
</style>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">{title}</h2>
        <p class="modal-content__desc">{message}</p>
    </div>
    <div class="actions">
        <Button cancel onClick={handleCancel}>{cancelLabel}</Button>
        <Button primary onClick={handleConfirm}>{confirmLabel}</Button>
    </div>
</div>
