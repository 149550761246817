import EditRetailerModal from 'EditRetailerModal.svelte';

const editRetailerModalOptions = {
    styleWindow: {
        position: 'relative',
        overflow: 'hidden',
        width: '500px',
        borderRadius: '0',
    },
    styleContent: {
        position: 'relative',
        padding: 0,
        maxHeight: '700px',
    },
    closeButton: false,
    closeOnEsc: false,
    closeOnOuterClick: true,
};

export { editRetailerModalOptions, EditRetailerModal };
