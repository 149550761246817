import db from 'services/db';
import { get } from 'svelte/store';
import shop from 'stores/shop';
import { convertDocsToArray, formatCurrency } from 'services/util';

import ORDERS_CONFIG from 'configs/orders';
import SHOP_CONFIG from 'configs/shop';

export async function getProductsForRetailers(retailers, lastDoc = null) {
    let productsRef = db.collection('products');

    productsRef = productsRef
        .where(
            'retailerId',
            'in',
            retailers.map((retailer) => retailer.id),
        )
        .orderBy('category', 'desc')
        .limit(SHOP_CONFIG.products.pagination.limit);

    if (lastDoc) {
        productsRef = productsRef.startAfter(lastDoc);
    }

    const docs = await productsRef.get();

    return {
        results: convertDocsToArray(docs),
        lastDoc: docs.docs[docs.docs.length - 1],
    };
}

export async function getProductsForRetailersV2(retailers, index = 0) {
    let productsRef = db.collection('products');

    productsRef = productsRef.where('retailerId', '==', retailers[index].id).orderBy('category', 'desc');

    const docs = await productsRef.get();

    return {
        results: convertDocsToArray(docs),
        index: index + 1,
    };
}

export async function getFeaturedProducts(countryId) {
    let productsRef = db
        .collection('products')
        .where('isFeatured', '==', true)
        .where('metadata.countryId', '==', countryId);
    const docs = await productsRef.get();

    return {
        results: convertDocsToArray(docs),
    };
}

export async function getFilteredProductsForRetailers(filters, retailers, lastDoc = null) {
    let productsRef = db.collection('products');

    // Filter by Retailer
    let retailerFilter = filters[SHOP_CONFIG.filters.keys.retailer];
    productsRef = productsRef.where('retailerId', 'in', retailerFilter || retailers.map((retailer) => retailer.id));

    // Filter by Category
    let categoryFilter = filters[SHOP_CONFIG.filters.keys.category];
    if (categoryFilter) {
        productsRef = productsRef.where('category', '==', categoryFilter);
    }

    // Sort
    let sortFilter =
        filters[SHOP_CONFIG.filters.keys.sort] ||
        (categoryFilter || retailerFilter
            ? { property: 'dateCreated', order: 'desc' }
            : { property: 'category', order: 'desc' });
    productsRef = productsRef
        .orderBy(sortFilter.property, sortFilter.order)
        .limit(SHOP_CONFIG.products.pagination.limit);
    if (lastDoc) {
        productsRef = productsRef.startAfter(lastDoc);
    }

    const docs = await productsRef.get();

    return {
        results: convertDocsToArray(docs),
        lastDoc: docs.docs[docs.docs.length - 1],
    };
}

export async function getFilteredProductsForRetailersV2(filters, retailers, index = 0) {
    let productsRef = db.collection('products');

    // Filter by Retailer
    // Default to all Retailers if Retailer Filter is empty
    let retailerFilter =
        filters && filters[SHOP_CONFIG.filters.keys.retailer] && filters[SHOP_CONFIG.filters.keys.retailer].length > 0
            ? filters[SHOP_CONFIG.filters.keys.retailer]
            : retailers.map((retailer) => retailer.id);

    productsRef = productsRef.where('retailerId', '==', retailerFilter[index]);

    // Filter by Category
    let categoryFilter = filters[SHOP_CONFIG.filters.keys.category];
    if (categoryFilter) {
        productsRef = productsRef.where('category', '==', categoryFilter);
    }

    const docs = await productsRef.get();

    return {
        results: convertDocsToArray(docs),
        index: index + 1,
    };
}

export async function getProductsBySearchQuery(searchQuery, countryId, lastDoc = null) {
    let productsRef = db.collection('products');

    productsRef = productsRef
        .where('metadata.countryId', '==', countryId)
        .orderBy('category', 'desc')
        .limit(SHOP_CONFIG.products.pagination.limit);
    if (searchQuery) {
        productsRef = productsRef.where(
            'metadata.keywords',
            'array-contains-any',
            searchQuery.toLowerCase().split(' '),
        );
    }

    if (lastDoc) {
        productsRef = productsRef.startAfter(lastDoc);
    }

    const docs = await productsRef.get();

    return {
        results: convertDocsToArray(docs),
        lastDoc: docs.docs[docs.docs.length - 1],
    };
}

export async function getProductsBySearchQueryV2(searchQuery, countryId, retailers = []) {
    let productsRef = db.collection('products');

    productsRef = productsRef.where('metadata.countryId', '==', countryId).orderBy('category', 'desc');

    if (searchQuery) {
        productsRef = productsRef.where(
            'metadata.keywords',
            'array-contains-any',
            searchQuery.toLowerCase().split(' '),
        );
    }

    const docs = await productsRef.get();

    // Only show products that are from active Retailers
    let retailerIds = retailers.map((retailer) => retailer.id);
    var results = convertDocsToArray(docs).filter((doc) => {
        return retailerIds.includes(doc.retailerId);
    });

    return {
        results,
    };
}

export function getRetailerDisplayName(retailerId) {
    return get(shop).retailers.find((retailer) => retailer.id == retailerId).displayName;
}

export function getRetailerLogoUrl(retailerId) {
    let shopStore = get(shop);
    const retailer = shopStore.retailers.find((retailer) => retailer.id == retailerId);
    if (retailer && retailer.logoUrl) {
        return retailer.logoUrl;
    } else {
        return '/images/default_retailer_logo@2x.png';
    }
}

export function placeOrder(user, shoppingCart) {
    function getConfirmationId() {
        let lastName = shoppingCart.customer.lastName.toUpperCase();
        let id = Math.random().toString(36).substr(2, 6).toUpperCase();
        return `${lastName}-${id}`;
    }

    // This does not actually include the Delivery fee
    let totalCost = shoppingCart.totalCost;
    let grandTotal;

    if (shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.creditCard) {
        grandTotal = totalCost + shoppingCart.loan.totalInterestCharge + shoppingCart.delivery.fee;
    } else {
        grandTotal = totalCost + shoppingCart.delivery.fee;
    }

    return db.collection('orders').add({
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        confirmationId: getConfirmationId(),
        country: user.country.displayName,
        countryId: user.country.id,
        products: shoppingCart.products.map((product) => {
            return {
                id: product.id,
                code: product.code,
                retailer: getRetailerDisplayName(product.retailerId),
                name: product.name,
                price: product.salePrice || product.price,
                formattedPrice: formatCurrency(
                    product.salePrice || product.price,
                    user.country.id,
                    user.country.currencyCode,
                ),
                quantity: product.quantity,
            };
        }),
        delivery: shoppingCart.delivery.isIncluded,
        deliveryFee: shoppingCart.delivery.fee,
        customer: shoppingCart.customer,
        paymentType: shoppingCart.paymentType,
        totalCost,
        grandTotal,
        loan: shoppingCart.loan,
        state: ORDERS_CONFIG.states.pending.id,
        formatted: {
            isFinancing: shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.finance,
            isCreditCard: shoppingCart.paymentType == ORDERS_CONFIG.paymentTypes.creditCard,
            totalCost: formatCurrency(totalCost, user.country.id, user.country.currencyCode), // NOTE! Does not include Delivery Fee or Taxes
            grandTotal: formatCurrency(grandTotal, user.country.id, user.country.currencyCode),
            totalCostWithInterest: formatCurrency(
                // Used for credit-card/cash payments
                totalCost + shoppingCart.loan.totalInterestCharge,
                user.country.id,
                user.country.currencyCode,
            ),
            monthlyPaymentAmount: formatCurrency(
                // Used for financing
                shoppingCart.loan.monthlyPaymentAmount,
                user.country.id,
                user.country.currencyCode,
            ),
            deliveryFee: formatCurrency(shoppingCart.delivery.fee, user.country.id, user.country.currencyCode),
        },
    });
}

export default {
    getFeaturedProducts,
    getProductsForRetailers,
    getProductsForRetailersV2,
    getFilteredProductsForRetailers,
    getRetailerDisplayName,
    getRetailerLogoUrl,
};
