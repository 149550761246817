import moment from 'moment';
import ORDERS_CONFIG from 'configs/orders';
import LOANS_CONFIG from 'configs/loans';

export function calculateLoan(country, paymentType, amount, numPeriods) {
    if (paymentType == ORDERS_CONFIG.paymentTypes.finance) {
        let today = new moment(),
            daysLeftInCurrentMonth = today.daysInMonth() - today.date();

        let startingMonth = moment().add(1, 'months').startOf('month').format('MMM YYYY');
        let endingMonth = moment().add(numPeriods, 'months').startOf('month').format('MMM YYYY');
        let monthlyPaymentAmount = pmt(
            getMonthlyInterestRate(country),
            numPeriods,
            amount + getInterestCharge(country, amount, daysLeftInCurrentMonth),
        );

        return {
            totalInterestCharge: parseFloat((monthlyPaymentAmount * numPeriods - amount).toFixed(2)),
            monthlyPaymentAmount: parseFloat(monthlyPaymentAmount.toFixed(2)),
            startingMonth,
            endingMonth,
            numPeriods,
        };
    } else {
        return {
            totalInterestCharge: parseFloat(amount * 0.03),
            monthlyPaymentAmount: parseFloat(amount * 1.03), // One time payment
            startingMonth: moment().startOf('month').format('MMM YYYY'),
            endingMonth: moment().startOf('month').format('MMM YYYY'),
            numPeriods: 0,
        };
    }
}

export function getLoanApplicationUrl(infinityId, loanAmount, firstName, lastName, emailAddress, phoneNumber) {
    return `https://www.yourtermfinance.com/MemberPage.aspx?subpage=applyForLoan&loanamount=${loanAmount}&storeid=${infinityId}&firstname=${firstName}&lastname=${lastName}&email=${emailAddress}&AdMethodText=3&AdMethodId=1&loantypeid=2`;
}

function getInterestCharge(country, amount, daysInMonth) {
    const monthlyInterestRate = getMonthlyInterestRate(country);
    const dailyInterestRate = getDailyInterestRate(country);

    if (amount <= 0) {
        return 0;
    }

    let interestCharged = amount * daysInMonth * dailyInterestRate;
    return interestCharged > monthlyInterestRate * amount ? monthlyInterestRate * amount : interestCharged;
}

function getMonthlyInterestRate(country) {
    return country.monthlyInterestRate || LOANS_CONFIG.interestRates[country.id];
}

function getDailyInterestRate(country) {
    return (getMonthlyInterestRate(country) * 12) / 365;
}

/**
 * Calculates the PMT
 * @param  {Float} rate [interest rate]
 * @param  {Float} nper [number of periods in months]
 * @param  {Float} pv [present value of loan]
 * @return {Float}
 */
function pmt(rate, nper, pv) {
    var pvif = Math.pow(1 + rate, -nper);
    var pmt = (pv * rate) / (1 - pvif);

    return pmt;
}

export default {
    calculateLoan,
    getLoanApplicationUrl,
};
