<script>
    import { onMount, getContext } from 'svelte';
    import Select from 'svelte-select';
    import { findWhere } from 'underscore';

    import user from 'stores/user';

    import adminService from 'services/admin';
    import mediaService from 'services/media';

    import SHOP_CONFIG from 'configs/shop';
    import USERS_CONFIG from 'configs/users';

    import Button from 'components/Button';

    const { close } = getContext('simple-modal');

    export let product = {};
    export let retailers;
    export let selectedCountry;
    export let onSaveProduct;

    const fileReader = new FileReader();

    let modal;
    let error;
    let tempImgFile;
    let tempImgUrl;
    let selectedRetailer;
    let selectedCategory;
    let keywords;
    let isLoading = false;

    onMount(() => {
        fileReader.addEventListener('load', (event) => {
            tempImgUrl = event.target.result;
        });

        keywords = getKeywords();

        selectedRetailer = findWhere(retailers, { value: product.retailerId });
        selectedCategory = findWhere(SHOP_CONFIG.filters.options.categories, { value: product.category });
    });

    async function handleSaveProduct(e) {
        e.preventDefault();
        error = null;

        if (!isValid()) {
            modal.parentElement.scrollTo(0, 0);
            error = 'Please fill in all of the required fields before submitting.';
            return;
        }

        isLoading = true;

        if (tempImgFile) {
            mediaService
                .upload(
                    `products/${selectedRetailer.value}`,
                    `${product.code}-${Math.random().toString(36).substr(2, 6).toUpperCase()}`,
                    'jpg',
                    tempImgFile,
                )
                .then(
                    (url) => {
                        product.imageUrl = url; // Update the URL referenced by the image
                        saveProduct();
                    },
                    (err) => {
                        console.error('Error', err);
                    },
                )
                .catch((err) => {
                    console.log(err);
                    error = err.message;
                });
        } else {
            saveProduct();
        }
    }

    function saveProduct() {
        setKeywords();
        product.retailerId = selectedRetailer.value;
        product.category = selectedCategory.value;

        let promise = product.id ? adminService.updateProduct(product) : adminService.addProduct(product);

        promise
            .then(
                () => {
                    onSaveProduct(product);
                    close();
                },
                (err) => {
                    console.log(err);
                    error = err.message;
                },
            )
            .finally(() => {
                isLoading = false;
            });
    }

    function isValid() {
        return (
            product.name &&
            selectedRetailer &&
            selectedCategory &&
            product.price &&
            product.code &&
            product.desc &&
            product.specs
        );
    }

    function handleUploadThumbnail(event) {
        tempImgFile = event.target.files[0];
        fileReader.readAsDataURL(tempImgFile);
    }

    function getKeywords() {
        return product.metadata && product.metadata.keywords ? product.metadata.keywords.join(', ') : null;
    }

    function setKeywords() {
        if (!product.metadata) {
            product.metadata = {
                countryId: selectedCountry.value,
            };
        }

        if (!keywords) {
            product.metadata.keywords = [];
        } else {
            let normalizedKeywords = keywords.trim();

            product.metadata.keywords = keywords.length
                ? normalizedKeywords
                      .toLowerCase()
                      .split(',')
                      .map((item) => {
                          return item.trim();
                      })
                : [];
        }
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .modal-title {
        background: $color-primary;
        padding: em(25) em(40);

        &__title {
            margin-bottom: em(10);
            font-size: em(24);
            font-weight: $fw-bold;
            color: $color-white;
        }

        &__desc {
            font-size: em(14);
            color: $color-white;
        }
    }

    .modal-content {
        margin: em(20) 0 em(60) 0;
        padding: 0 em(40);
    }

    .form {
        margin: 0;
        padding: 0;
    }

    .form-content {
        position: relative;
        margin: em(40) 0;

        &--compact {
            margin: em(10) 0 em(20) 0;
        }

        &__control {
            margin: em(25) 0;
        }

        &__label {
            display: block;
            margin-bottom: em(8);
            font-size: em(14);
            font-weight: $fw-bold;

            &--checkbox {
                margin-bottom: 0;
                margin-left: em(8);
            }
        }

        &__helper {
            display: block;
            margin-bottom: em(16);
            font-size: em(14);
            color: $color-gray-text;
        }

        &__input {
            width: 100%;
            padding: em(10);
            outline: 0;
            border: 1px solid $color-gray-text;
            border-radius: 3px;
            font-size: em(14);
        }

        &__select {
            width: 100%;
            font-size: em(14);
            --border: 1px solid #999999;
            --borderRadius: 0;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #822c7d;
            --multiItemActiveBG: #166f86;
            --multiClearBG: #999999;
        }

        &__textarea {
            @extend .form-content__input;
            min-height: 100px;
            line-height: 1.25;
        }

        &__checkbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row nowrap;
        }
    }

    .error {
        margin: em(25) 0;
        font-weight: $fw-bold;
        color: $color-primary;
    }

    .actions {
        padding: em(20) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }

    .thumbnail {
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 400px;
        height: 300px;
        margin: em(10) auto;
        border: 2px solid $color-gray-light;
        transition: all 0.5s ease;

        &:hover {
            border-color: $color-primary;
        }

        &__input {
            cursor: pointer;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        &__thumb {
            height: 100%;
        }
    }

    .thumbnail-actions {
        display: flex;
        justify-content: flex-end;
        margin: em(10) 0 em(20) 0;

        &__btn {
            @include btn-secondary;
            padding: 0 em(12);
            background: transparent;
            border: none;
            font-size: em(10);

            &:hover {
                background: transparent;
                color: darken($color-primary, 10%);
            }
        }
    }
</style>

<div class="modal" bind:this={modal}>
    <form class="form">
        <div class="modal-title">
            <h2 class="modal-title__title">{product.id ? 'Edit' : 'Add New'} Product</h2>
            <p class="modal-title__desc">
                {#if product.id}
                    Please note that all of your changes to this Product will be visbile to customers in real-time.
                {:else}Please note that once added this product will be visible to customers in real-time.{/if}
            </p>
        </div>
        <div class="modal-content">
            {#if error}
                <p class="error animate__animated animate__fadeInDown">Uh oh! {error}</p>
            {/if}
            <div class="form-content">
                <div class="form-content__control">
                    <label class="form-content__label">Image</label>
                    <p class="form-content__helper">
                        Click the thumbnail to upload a new image. New images will not persist until the Product is
                        saved. Please ensure that all images are 400px wide by 300px tall (4:3 aspect ratio).
                    </p>
                    <div class="thumbnail">
                        <input
                            required
                            on:change={handleUploadThumbnail}
                            class="thumbnail__input"
                            type="file"
                            multiple="false"
                            accept="image/png, image/jpg, image/jpeg" />
                        <img
                            alt="thumbnail"
                            src={tempImgUrl || product.imageUrl || '/images/default_product@2x.jpg'}
                            class="thumbnail__thumb animate__animated animate__fadeIn" />
                    </div>
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Name</label>
                    <input required name="name" bind:value={product.name} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Retailer</label>
                    <div class="form-content__select">
                        <Select
                            required
                            items={retailers}
                            placeholder="Select Retailer"
                            bind:selectedValue={selectedRetailer}
                            showIndicator={true}
                            isClearable={false}
                            showChevron={true} />
                    </div>
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Category</label>
                    <div class="form-content__select">
                        <Select
                            required
                            items={SHOP_CONFIG.filters.options.categories}
                            placeholder="Select Category"
                            bind:selectedValue={selectedCategory}
                            showIndicator={true}
                            isClearable={false}
                            showChevron={true} />
                    </div>
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Code</label>
                    <input required name="code" bind:value={product.code} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <div class="form-content__checkbox">
                        <input type="checkbox" bind:checked={product.inStock} />
                        <label class="form-content__label form-content__label--checkbox">In Stock</label>
                    </div>
                </div>
                {#if $user.profile.role != USERS_CONFIG.roles.retailerAdmin}
                    <div class="form-content__control">
                        <div class="form-content__checkbox">
                            <input type="checkbox" bind:checked={product.isFeatured} />
                            <label class="form-content__label form-content__label--checkbox">Is Featured</label>
                        </div>
                    </div>
                {/if}
                <div class="form-content__control">
                    <label class="form-content__label">Specs</label>
                    <input required name="specs" bind:value={product.specs} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Description</label>
                    <textarea required class="form-content__textarea" bind:value={product.desc} />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Keywords</label>
                    <div class="form-content__helper">
                        All keywords should be lower-case and comma-separated (i.e. keyword 1, keyword 2, keyword 3,
                        etc.).
                    </div>
                    <textarea class="form-content__textarea" bind:value={keywords} />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Price</label>
                    <input required name="price" bind:value={product.price} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Sale Price</label>
                    <input name="salePrice" bind:value={product.salePrice} type="text" class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Retailer Price</label>
                    <input
                        name="salePrice"
                        bind:value={product.retailerPrice}
                        type="text"
                        class="form-content__input" />
                </div>
            </div>
        </div>
        <div class="actions">
            <Button
                primary
                cancel
                onClick={(e) => {
                    e.preventDefault();
                    close();
                }}>
                Cancel
            </Button>
            <Button primary onClick={handleSaveProduct} disabled={isLoading}>
                {#if isLoading}Processing ...{:else}{product.id ? 'Update' : 'Add'} Product{/if}
            </Button>
        </div>
    </form>
</div>
