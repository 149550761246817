<script>
    import { onMount } from 'svelte';

    export let animationData = null;
    export let jsonPath = null;
    export let loop = true;

    // Generate a random ID so that we can reference
    // it in Lottie's 'loadAnimation' method
    let id = '_' + Math.random().toString(36).substr(2, 9);

    onMount(() => {
        let params = {
            container: document.querySelector(`#${id}`),
            renderer: 'svg',
            loop: loop,
            autoplay: true,
        };

        // Either pass the 'animationData' or JSON path
        // to Lottie
        if (animationData) {
            params.animationData = animationData;
        } else {
            params.path = jsonPath;
        }

        window.lottie.loadAnimation(params);
    });
</script>

<div {id} />
