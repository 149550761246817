<script>
    import { onMount, getContext } from 'svelte';
    import Select from 'svelte-select';
    import { findWhere, values } from 'underscore';

    import { generateRetailerId } from 'services/util';
    import adminService from 'services/admin';
    import mediaService from 'services/media';

    import Button from 'components/Button';

    import ORDERS_CONFIG from 'configs/orders';

    const { close } = getContext('simple-modal');

    export let order = {};

    let activities = [];

    const fileReader = new FileReader();

    let modal;
    let error;
    let tempImgFile;
    let tempImgUrl;
    let selectedCountry;
    let isLoading = false;

    onMount(async () => {
        if (order.id) {
            activities = await adminService.getOrderActivity(order.id);
        }
    });

    async function loadActivities() {
        activities = await adminService.getOrderActivity(order.id);
    }

    // Format timestamp
    function formatDate(timestamp) {
        if (!timestamp) return '';
        // Convert Firebase timestamp to JS Date
        const date = timestamp.toDate();
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
    }

    function formatState(stateId) {
        return values(ORDERS_CONFIG.states).find((state) => state.id == stateId);
    }

    function formatInternalState(internalStateId) {
        return values(ORDERS_CONFIG.internalStates).find((state) => state.id == internalStateId);
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .modal-title {
        background: $color-primary;
        padding: em(25) em(40);

        &__title {
            margin-bottom: em(10);
            font-size: em(24);
            font-weight: $fw-bold;
            color: $color-white;
        }

        &__desc {
            font-size: em(14);
            color: $color-white;
        }
    }

    .modal-content {
        margin: em(20) 0;
        padding: 0 em(10);
    }

    .form {
        margin: 0;
        padding: 0;
    }

    .form-content {
        position: relative;
        margin: em(40) 0;

        &--compact {
            margin: em(10) 0 em(20) 0;
        }

        &__control {
            margin: em(25) 0;
        }

        &__label {
            display: block;
            margin-bottom: em(8);
            font-size: em(14);
            font-weight: $fw-bold;

            &--checkbox {
                margin-bottom: 0;
                margin-left: em(8);
            }
        }

        &__helper {
            display: block;
            margin-bottom: em(16);
            font-size: em(14);
            color: $color-gray-text;
        }

        &__input {
            width: 100%;
            padding: em(10);
            outline: 0;
            border: 1px solid $color-gray-text;
            border-radius: 3px;
            font-size: em(14);
        }

        &__select {
            width: 100%;
            font-size: em(14);
            --border: 1px solid #999999;
            --borderRadius: 0;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #822c7d;
            --multiItemActiveBG: #166f86;
            --multiClearBG: #999999;
        }

        &__textarea {
            @extend .form-content__input;
            min-height: 100px;
            line-height: 1.25;
        }

        &__checkbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row nowrap;
        }
    }

    .error {
        margin: em(25) 0;
        font-weight: $fw-bold;
        color: $color-primary;
    }

    .actions {
        padding: em(20) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }

    .thumbnail {
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        margin: em(10) auto;
        border: 2px solid $color-gray-light;
        transition: all 0.5s ease;

        &:hover {
            border-color: $color-primary;
        }

        &__input {
            cursor: pointer;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        &__thumb {
            height: 100%;
        }
    }

    .thumbnail-actions {
        display: flex;
        justify-content: flex-end;
        margin: em(10) 0 em(20) 0;

        &__btn {
            @include btn-secondary;
            padding: 0 em(12);
            background: transparent;
            border: none;
            font-size: em(10);

            &:hover {
                background: transparent;
                color: darken($color-primary, 10%);
            }
        }
    }

    .table-container {
        width: 100%;
        overflow-x: auto;
    }

    .activity-table {
        width: 100%;
        border-collapse: collapse;
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        th,
        td {
            padding: 12px 16px;
            text-align: left;
            border-bottom: 1px solid #edf2f7;
        }

        th {
            font-weight: 600;
            color: #4a5568;
            background-color: #f7fafc;
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 0.05em;
        }

        td {
            color: #2d3748;
            font-size: 0.875rem;
        }

        tr:hover {
            background-color: #f7fafc;
        }
    }

    .timestamp {
        color: #718096;
        white-space: nowrap;
    }

    .user-info {
        display: flex;
        align-items: center;
        gap: 8px;

        .user-avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #e2e8f0;
        }

        .user-details {
            .name {
                font-weight: 500;
            }
            .email {
                color: #718096;
                font-size: 0.75rem;
            }
        }
    }

    .state-change {
        display: flex;
        align-items: center;
        gap: 8px;

        .state {
            font-weight: 500;
        }

        .arrow {
            color: #718096;
        }
    }

    .type {
        .automated {
            color: #718096;
            font-size: 0.75rem;
            margin-left: 4px;
        }
    }

    .empty-state {
        text-align: center;
        color: #718096;
        padding: 32px !important;
    }
</style>

<div class="modal" bind:this={modal}>
    <form class="form">
        <div class="modal-title">
            <h2 class="modal-title__title">Order Activity</h2>
            <p class="modal-title__desc">{order.confirmationId}</p>
        </div>
        <div class="modal-content">
            {#if error}
                <p class="error animate__animated animate__fadeInDown">Uh oh! {error}</p>
            {/if}
            <div class="table-container">
                <table class="activity-table">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>User</th>
                            <th>Change</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {#each activities as activity (activity.id)}
                            <tr>
                                <td class="timestamp">{formatDate(activity.timestamp)}</td>
                                <td>
                                    <div class="user-info">
                                        <div class="user-details">
                                            <div class="name">{activity.user.displayName || 'Unknown User'}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="state-change">
                                        <span class="state">
                                            {#if activity.type == 'internal_state_change'}
                                                {formatInternalState(activity.fromState) ? formatInternalState(activity.fromState).displayName : activity.fromState ? activity.fromState : 'N/A'}
                                            {:else if activity.type == 'state_change'}
                                                {formatState(activity.fromState) ? formatState(activity.fromState).displayName : activity.fromState}
                                            {:else}N/A{/if}
                                        </span>
                                        <span class="arrow">→</span>
                                        <span class="state">
                                            {#if activity.type == 'internal_state_change'}
                                                {formatInternalState(activity.toState) ? formatInternalState(activity.toState).displayName : activity.toState ? activity.toState : 'N/A'}
                                            {:else if activity.type == 'state_change'}
                                                {formatState(activity.toState) ? formatState(activity.toState).displayName : activity.toState}
                                            {:else}N/A{/if}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <span class="type">
                                        {activity.type == 'internal_state_change' ? 'Internal State Change' : 'State Change'}
                                        {#if activity.automated}
                                            <span class="automated">(Automated)</span>
                                        {/if}
                                    </span>
                                </td>
                            </tr>
                        {:else}
                            <tr>
                                <td colspan="4" class="empty-state">No activity found</td>
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
        </div>
        <div class="actions">
            <Button
                primary
                cancel
                onClick={(e) => {
                    e.preventDefault();
                    close();
                }}>
                Close
            </Button>
        </div>
    </form>
</div>
