<script>
    import { getContext, onMount } from 'svelte';
    import Select from 'svelte-select';
    import { push } from 'svelte-spa-router';

    import user from 'stores/user';
    import { getCountries } from 'services/user';
    import shop from 'stores/shop';
    import shoppingCart from 'stores/shoppingCart';
    import db from 'services/db';

    import Button from 'components/Button';

    const { close } = getContext('simple-modal');

    let countries = [];
    let selectedCountry = $user.country ? { label: $user.country.displayName, value: $user.country.id } : null;

    onMount(() => {
        loadCountries();
    });

    async function loadCountries() {
        getCountries().then((data) => {
            countries = data;
        });
    }

    function getCountryOptions() {
        return countries.map((country) => {
            return {
                label: country.displayName,
                value: country.id,
            };
        });
    }

    function handleClick() {
        let country = countries.find((country) => country.id === selectedCountry.value);
        user.setCountry(country);

        shop.fetch(selectedCountry.value).then(() => {
            shoppingCart.clear();
            push('/');
            close();
        });
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .modal-content {
        margin-bottom: em(60);
        padding: em(45) em(45) 0 em(45);

        &__title {
            margin-bottom: em(10);
            font-size: em(18);
            font-weight: $fw-bold;
            color: $color-primary;
        }

        &__desc {
            font-size: em(14);
            color: $color-gray-text;
        }
    }

    .country-select {
        margin: em(40) 0;
        font-size: em(14);

        &__label {
            margin-bottom: em(8);
            display: block;
            font-size: em(14);
        }
    }

    .actions {
        padding: em(10) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }
</style>

<div class="modal">
    <div class="modal-content">
        {#if !$user.country}
            <h2 class="modal-content__title">Welcome to the Term Finance Shop</h2>
            <p class="modal-content__desc">
                Our Term Finance shop offers you a great variety of products from various retailers, with the ability to
                finance your purchases over time.
            </p>
        {:else}
            <h2 class="modal-content__title">Leaving {$user.country.displayName}?</h2>
            <p class="modal-content__desc">
                Please note that available retailers, currency and pricing will differ by each Country. Contact us if
                you have any questions.
            </p>
        {/if}

        <div class="country-select">
            <label class="country-select__label">
                To get started,
                <strong>select your Country</strong>
                from the list below:
            </label>
            <Select
                items={countries ? getCountryOptions() : []}
                bind:selectedValue={selectedCountry}
                isDisabled={!countries.length}
                placeholder={countries ? 'Select a Country ...' : 'Loading Countries ...'} />
        </div>
    </div>
    <div class="actions">
        {#if $user.country}
            <Button
                primary
                cancel
                onClick={() => {
                    close();
                }}>
                Cancel
            </Button>
        {/if}
        <Button primary disabled={!selectedCountry} onClick={handleClick}>Start Shopping!</Button>
    </div>
</div>
