import { writable } from 'stores/localStorage';

const key = 'user';
const initialValue = {
    country: null,
    profile: null,
};

function createStore() {
    const { subscribe, update } = writable(key, initialValue);

    return {
        subscribe,

        setCountry: (country) => {
            update((store) => {
                return { ...store, country };
            });
        },

        setProfile: (profile) => {
            update((store) => {
                return { ...store, profile };
            });
        },
    };
}

export default createStore();
