<script>
    import { onMount, getContext } from 'svelte';
    import { link, push } from 'svelte-spa-router';

    import authService from 'services/auth';

    import USERS_CONFIG from 'configs/users';

    import AnimatedGrid from 'components/AnimatedGrid';
    import Button from 'components/Button';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';

    const { open } = getContext('simple-modal');

    let username;
    let password;
    let isLoading = false;

    onMount(() => {
        isLoading = true;
    });

    firebase.auth().onAuthStateChanged(function (u) {
        if (u) {
            authenticate(u.uid);
        } else {
            isLoading = false;
        }
    });

    function authenticate(id, cb) {
        authService.authenticate(id).then((userProfile) => {
            isLoading = false;

            if (!userProfile) {
                // Do not let the user log in if we can't fetch a profile
                authService.logout().then(() => {
                    open(
                        AlertModal,
                        {
                            title: 'Uh oh! We were unable to log you in.',
                            message:
                                'Your permissions need to be updated. Please contact your site administrator to get access.',
                        },
                        alertModalOptions,
                    );
                });
            } else {
                setTimeout(() => {
                    if (userProfile.role == USERS_CONFIG.roles.retailerAdmin) {
                        push('/admin/products');
                    } else {
                        push('/admin/orders/pending');
                    }
                }, 100);
            }
        });
    }

    function handleLogin(e) {
        e.preventDefault();
        isLoading = true;

        firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
            .then(function (data) {
                authenticate(data.user.uid);
            })
            .catch(function (error) {
                open(
                    AlertModal,
                    {
                        title: 'Uh oh! We were unable to log you in.',
                        message: error.message,
                    },
                    alertModalOptions,
                );
            })
            .finally(() => {
                isLoading = false;
            });
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .root {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
    }

    .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: 1;
        flex: 1;
        height: 100vh;

        background: $color-white;
        box-shadow: 0 2px 10px 5px rgba($color-black, 0.35);
    }

    .right {
        flex: 1;
        height: 100vh;
        background: url('/images/admin_bg@2x.jpg') no-repeat center;
        background-size: cover;
    }

    .login {
        padding: em(50);

        &__title {
            margin-top: em(70);
            margin-bottom: em(30);
            font-weight: $fw-bold;
            font-size: em(24);
            color: $color-black;
        }

        &__subtitle {
            margin: em(25) 0 em(50) 0;
            font-weight: $fw-regular;
            color: $color-gray-text;
        }

        &__link {
            display: inline-block;
            margin-top: em(175);

            color: $color-black;

            &:hover {
                color: $color-primary;
            }
        }
    }

    .login-form {
        &__inputs {
            margin-bottom: em(25);
        }

        &__input {
            width: 500px;
            height: 65px;
            border: 1px solid $color-gray-dark;
            outline: 0;
            margin: 0;
            padding: em(10);
            font-size: em(16);
            color: $color-primary;
            font-weight: $fw-bold;
            transition: 0.25s all ease-in;

            &:focus {
                border-left: 6px solid $color-primary;
            }
        }

        &__btn {
            width: 250px;
        }

        input::placeholder {
            font-weight: $fw-regular;
        }
    }
</style>

<div class="root">
    <div class="left">
        <div class="login">
            <div class="login__logo">
                <img src="/images/logo_big@2x.svg" height="69" alt="logo" />
                <h2 class="login__title">
                    Offering loans to individuals and businesses in a transparent, affordable and convienient way.
                </h2>
                <h3 class="login__subtitle">Welcome back. Please login to your account.</h3>
                <form class="login-form">
                    <div class="login-form__inputs">
                        <input
                            bind:value={username}
                            type="text"
                            name="username"
                            class="login-form__input"
                            placeholder="Email Address" />
                        <input
                            bind:value={password}
                            type="password"
                            name="password"
                            class="login-form__input"
                            placeholder="Password" />
                    </div>
                    <div class="login-form__btn">
                        <Button
                            onClick={handleLogin}
                            {isLoading}
                            primary
                            fullWidth
                            disabled={!username || !password || isLoading}>
                            {isLoading ? 'Logging in' : 'Login'}
                        </Button>
                    </div>
                    <a use:link href="/" class="login__link">Back to Shop</a>
                </form>
            </div>
        </div>
    </div>
    <div class="right">
        <AnimatedGrid opacity="0.1" />
    </div>
</div>
