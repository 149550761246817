import CountrySelectorModal from 'CountrySelectorModal.svelte';

const countrySelectorModalOptions = {
    styleWindow: {
        width: '500px',
        borderRadius: '0',
    },
    styleContent: {
        padding: 0,
    },
    closeButton: false,
    closeOnEsc: false,
    closeOnOuterClick: false,
};

export { countrySelectorModalOptions, CountrySelectorModal };
