<script>
    import { onMount, onDestroy } from 'svelte';

    import user from 'stores/user';
    import shop from 'stores/shop';
    import {
        getProductsForRetailersV2,
        getFeaturedProducts,
        getFilteredProductsForRetailersV2,
        getProductsBySearchQueryV2,
    } from 'services/shop';
    import { push } from 'svelte-spa-router';

    import SHOP_CONFIG from 'configs/shop';

    import Nav from 'components/Nav';
    import Page from 'components/Page';
    import Header from 'components/Header';
    import ProductGrid from 'components/ProductGrid';
    import ProductFilters from 'components/ProductGrid/ProductFilters';
    import ShoppingCartCalculator from 'components/ShoppingCartCalculator';
    import FeaturedProducts from 'components/FeaturedProducts';
    import Lottie from 'components/Lottie';

    export let params = {};

    let observer;
    let products = [];
    let featuredProducts = [];
    let resultsType; // The type of results we are displaying
    let index; // The index of the Retailer to search for
    let canSeeFeaturedProducts;
    let canSeeFilters = true;

    let filters;
    let searchQuery;

    let isLoadingProducts = true;
    let isLoadingMoreProducts = false;
    let isLoadingFeaturedProducts = true;

    let retailer;

    let isObserving = false;
    let sentinel;

    $: {
        canSeeFeaturedProducts =
            !isLoadingFeaturedProducts &&
            featuredProducts.length > 0 &&
            !params.retailerId &&
            (resultsType == SHOP_CONFIG.products.pagination.type.default ||
                (filters && !filters.retailer && !filters.category & !filters.sort));

        if (retailer && params.retailerId != retailer.id) {
            loadRetailer(params.retailerId);
        }

        canSeeFilters = !params.retailerId;
    }

    onMount(() => {
        resultsType = SHOP_CONFIG.products.pagination.type.default;

        // Set up Observer
        initObserver();
    });

    let unsubscribe = user.subscribe((user) => {
        if (user.country) {
            shop.fetch(user.country.id).then(() => {
                getFeaturedProducts(user.country.id).then((data) => {
                    isLoadingFeaturedProducts = false;
                    featuredProducts = data.results;
                });

                // If we're on the retailer page load the Retailer
                if (params.retailerId) {
                    loadRetailer(params.retailerId); // Loads Products for a specified Retailer
                } else {
                    loadProducts();
                }
            });
        }
    });

    onDestroy(() => {
        unsubscribe();
        unobserve();
    });

    function initObserver() {
        sentinel = document.querySelector('#sentinel');
        observer = new IntersectionObserver(
            (entries) => {
                if (entries.some((entry) => entry.intersectionRatio > 0) && isObserving && !isLoadingMoreProducts) {
                    loadMoreProducts();
                } else {
                    isObserving = true; // Prevents callback from being called the first time 'observe' is triggered
                }
            },
            {
                threshold: 0.5,
            },
        );
    }

    function observe() {
        observer.observe(sentinel);
        isObserving = true;
    }

    function unobserve() {
        if (observer) {
            isObserving = false;
            observer.unobserve(document.querySelector('#sentinel'));
        }
    }

    function displayProducts(promise) {
        products = [];
        isLoadingProducts = true;

        promise
            .then((data) => {
                products = data.results;
                index = data.index;

                let retailers =
                    filters &&
                    filters[SHOP_CONFIG.filters.keys.retailer] &&
                    filters[SHOP_CONFIG.filters.keys.retailer].length > 0
                        ? filters[SHOP_CONFIG.filters.keys.retailer]
                        : $shop.retailers;

                // If we're on a Retailer page then just load data for the Retailer specified
                if (retailer) {
                    retailers = [retailer];
                }

                // If there's less than 8 products for this Retailer then automatically
                // load more. This prevents the user from seeing empty space in the grid.
                if (data.results.length < SHOP_CONFIG.products.pagination.min && index <= retailers.length - 1) {
                    loadMoreProducts();
                }

                if (index <= retailers.length - 1 && !isObserving) {
                    observe();
                }
            })
            .finally(() => {
                setTimeout(() => {
                    isLoadingProducts = false;
                }, 500);
            });
    }

    function resetPagination() {
        unobserve();
        index = 0;
    }

    function loadMoreProducts() {
        let promise;
        let retailers;
        isLoadingMoreProducts = true;

        switch (resultsType) {
            case SHOP_CONFIG.products.pagination.type.default:
                retailers = $shop.retailers;
                promise = getProductsForRetailersV2($shop.retailers, index);
                break;
            case SHOP_CONFIG.products.pagination.type.filter:
                retailers =
                    filters &&
                    filters[SHOP_CONFIG.filters.keys.retailer] &&
                    filters[SHOP_CONFIG.filters.keys.retailer].length > 0
                        ? filters[SHOP_CONFIG.filters.keys.retailer]
                        : $shop.retailers;
                promise = getFilteredProductsForRetailersV2(filters, $shop.retailers, index);
                break;
            case SHOP_CONFIG.products.pagination.type.search:
                promise = getProductsBySearchQueryV2(searchQuery, $user.country.id, $shop.retailers);
                break;
        }

        promise
            .then((data) => {
                products = products.concat(data.results);
                index = data.index;

                if (index > retailers.length - 1) {
                    unobserve();
                } else if (data.results.length < SHOP_CONFIG.products.pagination.min) {
                    // If there's less than 8 products for this Retailer then automatically
                    // load more. This prevents the user from seeing empty space in the grid.
                    loadMoreProducts();
                }
            })
            .finally(() => {
                isLoadingMoreProducts = false;
            });
    }

    function loadProducts(retailers = null) {
        if (!retailers) {
            retailers = $shop.retailers;
        }

        resetPagination();
        resultsType = SHOP_CONFIG.products.pagination.type.default;
        displayProducts(getProductsForRetailersV2(retailers));
    }

    function loadRetailer(retailerId) {
        retailer = $shop.retailers.find((retailer) => {
            return retailer.id == retailerId || retailer.displayName.replace(`-${$user.country.id}`) == retailerId;
        });

        if (retailer) {
            loadProducts([retailer]);
        } else {
            console.error('Retailer with ID ' + params.retailerId + ' does not exist! Redirecting to homepage ...');
            push('/');
        }
    }

    function handleFilterSelect(event) {
        resetPagination();
        resultsType = SHOP_CONFIG.products.pagination.type.filter;
        filters = event.detail;
        displayProducts(getFilteredProductsForRetailersV2(filters, $shop.retailers));
    }

    function handleSearch(event) {
        resetPagination();
        searchQuery = event.detail.searchQuery;

        if (searchQuery != null) {
            resultsType = SHOP_CONFIG.products.pagination.type.search;
            displayProducts(getProductsBySearchQueryV2(searchQuery, $user.country.id, $shop.retailers));
        } else {
            loadProducts();
        }
    }
</script>

<style lang="scss">
    @import 'sass/base';

    #sentinel {
        position: absolute;
        z-index: -1;
        bottom: 20%;
        right: 0;
        opacity: 0;
        width: 1px;
        height: 1px;
    }

    .root {
        height: 100%;
    }

    .indicator {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        margin-top: em(300);

        &__icon {
            width: 120px;
            height: auto;
        }

        &__title {
            font-size: em(24);
            color: $color-gray-text;

            &--loader {
                margin-top: em(-5);
                margin-bottom: em(10);
            }

            &--no-results {
                margin-top: em(-5);
                margin-bottom: em(8);
            }
        }

        &__subtitle {
            font-size: em(14);
            color: rgba($color-gray-text, 0.8);
        }
    }

    .loading {
        margin-top: 5em;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
            width: 120px;
            height: auto;
        }
    }
</style>

<div id="root" class="root">
    <Nav on:search={handleSearch} />
    <Page>
        <div slot="header">
            <Header>
                <span slot="title">
                    {#if retailer}{retailer.displayName}{:else}Shop{/if}
                </span>
            </Header>
        </div>
        <div slot="content">
            {#if canSeeFilters}
                <ProductFilters on:filterSelect={handleFilterSelect} />
            {/if}
            {#if canSeeFeaturedProducts}
                <FeaturedProducts isLoading={isLoadingFeaturedProducts} products={featuredProducts} />
            {/if}
            {#if !isLoadingProducts && products.length}
                <ProductGrid {products} />
            {:else if isLoadingProducts}
                <div class="indicator animate__animated animate__fadeIn">
                    <div class="indicator__icon">
                        <Lottie jsonPath="lottie/products-loading-2.json" />
                    </div>
                    <div class="indicator__title indicator__title--loader">Searching for Products</div>
                    <div class="indicator__subtitle">Hang tight, we'll be with you shortly ...</div>
                </div>
            {:else}
                <div class="indicator animate__animated animate__fadeIn">
                    <div class="indicator__icon">
                        <Lottie jsonPath="lottie/products-empty.json" loop={false} />
                    </div>
                    <div class="indicator__title indicator__title--no-results">Oops! We found no results.</div>
                    <div class="indicator__subtitle">Try modifying your search or filters and trying again.</div>
                </div>
            {/if}
            {#if isLoadingMoreProducts}
                <div class="loading animate__animated animate__fadeIn">
                    <div class="loading__icon">
                        <Lottie jsonPath="lottie/products-loading-2.json" />
                    </div>
                </div>
            {/if}
            <div id="sentinel" />
        </div>
    </Page>
    <ShoppingCartCalculator />
</div>
