<script>
    export let value = 0;
    export let onIncrement = () => {};
    export let onDecrement = () => {};
</script>

<style lang="scss">
    @import 'sass/base';

    .counter {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 35px;
        border: 1px solid $color-gray-dark;
    }

    .control {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 100%;
        background: $color-gray-light;
        font-size: em(18);
        color: $color-gray-text;
        transition: all 0.25s ease;

        &:hover {
            background: $color-primary;
            color: $color-white;
        }
    }

    .input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 100%;
        background: $color-white;
        border-right: 1px solid $color-gray-dark;
        border-left: 1px solid $color-gray-dark;
    }
</style>

<div class="counter">
    <div class="control" on:click={onDecrement}>-</div>
    <div class="input">{value}</div>
    <div class="control" on:click={onIncrement}>+</div>
</div>
