import db from 'services/db';
import { convertDocsToArray } from 'services/util';

export async function getCountries() {
    let docs = await db.collection('countries').where('isActive', '==', true).get();
    return convertDocsToArray(docs);
}

export async function getCountryById(countryId) {
    var countryDoc = await db.collection('countries').doc(countryId).get();

    return countryDoc.exists
        ? {
              id: countryId,
              ...countryDoc.data(),
          }
        : null;
}

export async function getLocationsByCountryId(countryId) {
    var docs = await db.collection('countries').doc(countryId).collection('locations').get();
    return convertDocsToArray(docs);
}
