<script>
    import { onMount, getContext } from 'svelte';
    import Select from 'svelte-select';
    import { findWhere } from 'underscore';

    import { generateRetailerId } from 'services/util';
    import adminService from 'services/admin';
    import mediaService from 'services/media';

    import Button from 'components/Button';

    const { close } = getContext('simple-modal');

    export let retailer = {};
    export let countries;
    export let onSaveRetailer;

    const fileReader = new FileReader();

    let modal;
    let error;
    let tempImgFile;
    let tempImgUrl;
    let selectedCountry;
    let isLoading = false;

    onMount(() => {
        fileReader.addEventListener('load', (event) => {
            tempImgUrl = event.target.result;
        });

        selectedCountry = findWhere(countries, { value: retailer.countryId });
    });

    async function handleSaveRetailer(e) {
        e.preventDefault();
        error = null;

        if (!isValid()) {
            modal.parentElement.scrollTo(0, 0);
            error = 'Please fill in all of the required fields before submitting.';
            return;
        }

        isLoading = true;

        if (tempImgFile) {
            mediaService
                .upload(
                    `retailers/logos`,
                    `${generateRetailerId(retailer.displayName, selectedCountry.value)}-${Math.random()
                        .toString(36)
                        .substr(2, 6)
                        .toUpperCase()}`,
                    'jpg',
                    tempImgFile,
                )
                .then(
                    (url) => {
                        retailer.logoUrl = url; // Update the URL referenced by the image
                        saveRetailer();
                    },
                    (err) => {},
                )
                .catch((err) => {
                    console.log(err);
                    error = err.message;
                });
        } else {
            saveRetailer();
        }
    }

    function saveRetailer() {
        retailer.countryId = selectedCountry.value;

        adminService
            .saveRetailer(retailer)
            .then(
                () => {
                    onSaveRetailer(retailer);
                    close();
                },
                (err) => {
                    console.log(err);
                    error = err.message;
                },
            )
            .finally(() => {
                isLoading = false;
            });
    }

    function isValid() {
        return retailer.displayName && selectedCountry;
    }

    function handleUploadThumbnail(event) {
        tempImgFile = event.target.files[0];
        fileReader.readAsDataURL(tempImgFile);
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .modal-title {
        background: $color-primary;
        padding: em(25) em(40);

        &__title {
            margin-bottom: em(10);
            font-size: em(24);
            font-weight: $fw-bold;
            color: $color-white;
        }

        &__desc {
            font-size: em(14);
            color: $color-white;
        }
    }

    .modal-content {
        margin: em(20) 0 em(60) 0;
        padding: 0 em(40);
    }

    .form {
        margin: 0;
        padding: 0;
    }

    .form-content {
        position: relative;
        margin: em(40) 0;

        &--compact {
            margin: em(10) 0 em(20) 0;
        }

        &__control {
            margin: em(25) 0;
        }

        &__label {
            display: block;
            margin-bottom: em(8);
            font-size: em(14);
            font-weight: $fw-bold;

            &--checkbox {
                margin-bottom: 0;
                margin-left: em(8);
            }
        }

        &__helper {
            display: block;
            margin-bottom: em(16);
            font-size: em(14);
            color: $color-gray-text;
        }

        &__input {
            width: 100%;
            padding: em(10);
            outline: 0;
            border: 1px solid $color-gray-text;
            border-radius: 3px;
            font-size: em(14);
        }

        &__select {
            width: 100%;
            font-size: em(14);
            --border: 1px solid #999999;
            --borderRadius: 0;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #822c7d;
            --multiItemActiveBG: #166f86;
            --multiClearBG: #999999;
        }

        &__textarea {
            @extend .form-content__input;
            min-height: 100px;
            line-height: 1.25;
        }

        &__checkbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row nowrap;
        }
    }

    .error {
        margin: em(25) 0;
        font-weight: $fw-bold;
        color: $color-primary;
    }

    .actions {
        padding: em(20) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }

    .thumbnail {
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        margin: em(10) auto;
        border: 2px solid $color-gray-light;
        transition: all 0.5s ease;

        &:hover {
            border-color: $color-primary;
        }

        &__input {
            cursor: pointer;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        &__thumb {
            height: 100%;
        }
    }

    .thumbnail-actions {
        display: flex;
        justify-content: flex-end;
        margin: em(10) 0 em(20) 0;

        &__btn {
            @include btn-secondary;
            padding: 0 em(12);
            background: transparent;
            border: none;
            font-size: em(10);

            &:hover {
                background: transparent;
                color: darken($color-primary, 10%);
            }
        }
    }
</style>

<div class="modal" bind:this={modal}>
    <form class="form">
        <div class="modal-title">
            <h2 class="modal-title__title">{retailer.id ? 'Edit' : 'Add New'} Retailer</h2>
            <p class="modal-title__desc">
                {#if retailer.id}
                    Please note that all of your changes to this Retailer will be visbile to customers in real-time.
                {:else}Please note that once added this Retailer will be visible to customers in real-time.{/if}
            </p>
        </div>
        <div class="modal-content">
            {#if error}
                <p class="error animate__animated animate__fadeInDown">Uh oh! {error}</p>
            {/if}
            <div class="form-content">
                <div class="form-content__control">
                    <label class="form-content__label">Logo</label>
                    <p class="form-content__helper">
                        Click the thumbnail to upload a new image. New images will not persist until the Retailer is
                        saved. Please ensure that all images are 100px wide by 100px tall.
                    </p>
                    <div class="thumbnail">
                        <input
                            required
                            on:change={handleUploadThumbnail}
                            class="thumbnail__input"
                            type="file"
                            multiple="false"
                            accept="image/png, image/jpg, image/jpeg" />
                        <img
                            alt="thumbnail"
                            src={tempImgUrl || retailer.logoUrl || '/images/default_retailer_logo@2x.png'}
                            class="thumbnail__thumb animate__animated animate__fadeIn" />
                    </div>
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Name</label>
                    <input
                        required
                        name="name"
                        bind:value={retailer.displayName}
                        type="text"
                        class="form-content__input" />
                </div>
                <div class="form-content__control">
                    <label class="form-content__label">Country</label>
                    <div class="form-content__select">
                        <Select
                            required
                            items={countries}
                            placeholder="Select Country"
                            bind:selectedValue={selectedCountry}
                            showIndicator={true}
                            isClearable={false}
                            showChevron={true} />
                    </div>
                </div>
                <div class="form-content__control">
                    <div class="form-content__checkbox">
                        <input type="checkbox" bind:checked={retailer.isActive} />
                        <label class="form-content__label form-content__label--checkbox">Is Active</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <Button
                primary
                cancel
                onClick={(e) => {
                    e.preventDefault();
                    close();
                }}>
                Cancel
            </Button>
            <Button primary onClick={handleSaveRetailer} disabled={isLoading}>
                {#if isLoading}Processing ...{:else}{retailer.id ? 'Update' : 'Add'} Retailer{/if}
            </Button>
        </div>
    </form>
</div>
