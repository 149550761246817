<script>
    import { onMount } from 'svelte';

    import Router, { location } from 'svelte-spa-router';
    import Modal from 'svelte-simple-modal';

    export let routes;

    $: {
        // Scroll to the top of the page whenever the
        // '$location' cha    import { debug } from 'svelte/internal';
        if ($location) window.scrollTo(0, 0);
    }

    onMount(() => {
        if ('<@ENV@>' == 'PROD') {
            try {
                const appCheck = firebase.appCheck();
                appCheck.activate('6Le5t30qAAAAAEngPWOdAMlhX37VCOl-UvKY6tgE', true);
                console.debug('AppCheck initialized: Production');
            } catch (e) {
                console.error(e);
            }

            firebase.analytics();
        } else if ('<@ENV@>' == 'STAGING') {
            try {
                const appCheck = firebase.appCheck();
                appCheck.activate('6LfzBXwqAAAAAJ90vS9rJWHtPlP6cXGE3Dafr059', true);
                console.debug('AppCheck initialized: Staging');
            } catch (e) {
                console.error(e);
            }
        } else {
            // Enable debug mode for development (remove in production)
            self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
            console.debug('AppCheck initialized: Debug Mode');
        }
    });

    function handleConditionsFailed(event) {
        event.detail.userData.onConditionsFailed();
    }
</script>

<style lang="scss">
    @import 'sass/base';

    :global(body) {
        background: #e9eae9;
        font-size: em(13);
        font-family: $ff-primary;
        font-weight: $fw-regular;
        line-height: 1;

        @include for-tablet-portrait-up {
            font-size: em(14);
        }

        @include for-tablet-landscape-up {
            font-size: em(16);
        }
    }

    :global(a) {
        text-decoration: none;
        color: $color-primary;

        &:hover {
            color: darken($color-primary, 10%);
        }
    }

    :global(p) {
        line-height: 1.25;
    }

    :global(strong) {
        font-weight: $fw-bold;
    }

    :global(table) {
        width: 100%;
        border: solid 1px $color-gray-dark;
        border-collapse: collapse;
        border-spacing: 0;
    }

    :global(th) {
        padding: em(20);
        text-align: left;
        border-bottom: 1px solid $color-gray-dark;
        border-right: 1px solid $color-gray-dark;
        font-size: em(14);
    }

    :global(td) {
        padding: em(15);
        border: solid 1px $color-gray-dark;
        font-size: em(13);
        color: $color-black;
    }
</style>

<main>
    <Modal>
        <Router {routes} on:conditionsFailed={handleConditionsFailed} />
    </Modal>
</main>
