export default {
    paymentTypes: {
        finance: 1000,
        creditCard: 2000,
    },
    states: {
        pending: {
            id: 1000,
            key: 'pending',
            displayName: 'Pending',
        },
        pendingNonPartner: {
            id: 9000,
            key: 'pendingNonPartner',
            displayName: 'Pending (Non Partner)',
        },
        confirmed: {
            id: 2000,
            key: 'confirmed',
            displayName: 'Confirmed',
        },
        approved: {
            id: 3000,
            key: 'approved',
            displayName: 'Credit Approved',
        },
        procured: {
            id: 7000,
            key: 'procured',
            displayName: 'Products Procured',
        },
        scheduled: {
            id: 8000,
            key: 'scheduled',
            displayName: 'Delivery Scheduled',
        },
        transit: {
            id: 4000,
            key: 'transit',
            displayName: 'Out for Delivery',
        },
        delivered: {
            id: 5000,
            key: 'delivered',
            displayName: 'Delivered',
        },
        cancelled: {
            id: 6000,
            key: 'cancelled',
            displayName: 'Cancelled',
        },
    },
    internalStates: {
        newOrder: {
            id: 1,
            key: 'newOrder',
            displayName: 'New Order',
        },
        customerContacted: {
            id: 2,
            key: 'customerContacted',
            displayName: 'Customer Contacted',
        },
        orderConfirmed: {
            id: 3,
            key: 'orderConfirmed',
            displayName: 'Order Confirmed',
        },
        docsRequested: {
            id: 4,
            key: 'docsRequested',
            displayName: 'Documents Requested',
        },
        verficationEmailSent: {
            id: 5,
            key: 'verificationEmailSent',
            displayName: 'Verification Email Sent',
        },
        informedRepayment: {
            id: 6,
            key: 'informedRepayment',
            displayName: 'Informed Repayment',
        },
        informedSignature: {
            id: 7,
            key: 'informedSign',
            displayName: 'Informed to Sign',
        },
        assignmentSent: {
            id: 8,
            key: 'assignmentSent',
            displayName: 'Assignment Sent',
        },
        updateCustomer: {
            id: 9,
            key: 'updateCustomer',
            displayName: 'Update Customer on Order Status',
        },
        assignmentConfirmed: {
            id: 10,
            key: 'assignmentConfirmed',
            displayName: 'Assignment Confirmed',
        },
        procurementEmailSent: {
            id: 11,
            key: 'procurementEmailSent',
            displayName: 'Procurement Email Sent',
        },
        deliveryMessageSent: {
            id: 12,
            key: 'deliveryMessageSent',
            displayName: 'Delivery Message Sent',
        },
    },
};
