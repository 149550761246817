<script>
    import { fly } from 'svelte/transition';

    import ProductGridItem from 'components/ProductGrid/ProductGridItem';
    import Lottie from 'components/Lottie';

    export let products = [];
    export let isLoading = true;

    let productsElement;

    function scroll(dir) {
        productsElement.scrollTo({
            top: productsElement.scrollTop,
            left: dir == 'right' ? productsElement.scrollLeft + 300 : productsElement.scrollLeft - 300,
            behavior: 'smooth',
        });
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .fp {
        position: relative;
        width: 100%;
        min-height: 300px;
        margin-bottom: em(25);
        background: $color-white;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);

        &__title {
            align-self: flex-start;
            display: inline-block;
            padding: em(10) em(20);
            background: $color-primary;
            border-left: 5px solid darken($color-primary, 5%);
            border-top: 2px solid lighten($color-primary, 5%);
            font-size: em(14);
            font-weight: $fw-bold;
            color: $color-white;
            text-transform: uppercase;
            letter-spacing: em(2);
        }

        &__arrow {
            position: absolute;
            z-index: 200;
            top: 40%;
            width: 30px;
            height: 30px;
            background: $color-white;
            border-radius: 50%;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
            cursor: pointer;

            @include for-tablet-portrait-up {
                width: 50px;
                height: 50px;
            }

            @include for-tablet-landscape-up {
                width: 75px;
                height: 75px;
            }

            &--right {
                right: -10px;

                @include for-tablet-portrait-up {
                    right: -30px;
                }
            }

            &--left {
                left: -10px;

                @include for-tablet-portrait-up {
                    left: -30px;
                }
            }
        }
    }

    .indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 300px;

        &__loader {
            width: 100px;
            height: 100px;
        }
    }

    .products {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        &__product {
            flex: 0 0 auto;
            max-width: 49%;
            margin: 0 em(3);

            @include for-tablet-portrait-up {
                max-width: 250px;
            }

            @include for-tablet-landscape-up {
                max-width: 300px;
            }
        }
    }
</style>

<div class="fp">
    <div class="fp__title">Featured Products</div>
    {#if products.length > 0 && !isLoading}
        <div class="fp__arrow fp__arrow--right" on:click={() => scroll('right')} on:mouseenter={() => scroll('right')}>
            <Lottie jsonPath="lottie/fp-arrow-right.json" />
        </div>
        <div class="fp__arrow fp__arrow--left" on:click={() => scroll('left')} on:mouseenter={() => scroll('left')}>
            <Lottie jsonPath="lottie/fp-arrow-left.json" />
        </div>
        <div bind:this={productsElement} class="products">
            {#each products as product, i}
                <div class="products__product">
                    <div in:fly={{ x: -100, duration: 500, delay: i * 150 }}>
                        <ProductGridItem {product} />
                    </div>
                </div>
            {/each}
        </div>
    {:else if isLoading}
        <div class="indicator">
            <div class="indicator__loader">
                <Lottie jsonPath="lottie/products-loading-2.json" />
            </div>
        </div>
    {/if}
</div>
